import React from 'react';

import { UI } from './Icons';

import './StarRating.scss';

const Star = ({
  marked,
  starId,
  onMouseOver,
  onClick,
}: {
  marked: boolean;
  starId: number;
  onMouseOver(starId: number | null): void;
  onClick(starId: number): void;
}) => {
  return (
    <span
      className='star-wrapper'
      onMouseOver={() => onMouseOver(starId)}
      onClick={() => onClick(starId)}
    >
      <UI.StarIcon className={marked ? 'checked' : 'unchecked'} />
    </span>
  );
};

export const StarRating = ({
  value,
  onValueChange,
}: {
  value: number;
  onValueChange(value: number): void;
}) => {
  const [selection, setSelection] = React.useState(0);

  const hoverOver = (starId: number | null) => {
    const val = starId ?? 0;
    setSelection(val);
  };

  const onClick = (starId: number) => {
    onValueChange(starId);
  };

  return (
    <div className='star-rating' onMouseOut={() => hoverOver(null)}>
      {Array.from({ length: 5 }, (v, i) => (
        <Star
          starId={i + 1}
          key={`star_${i + 1} `}
          marked={selection ? selection >= i + 1 : value >= i + 1}
          onMouseOver={hoverOver}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
