import React from 'react';

import { Responsive } from './ResponsiveContainer';

import './Tabs.scss';

interface TabsProps {
  activekey: string;
  className?: string;
  onSelect(activeKey: string): void;
}

export const Tabs = (props: React.PropsWithChildren<TabsProps>) => {
  const renderTabItem = (child: React.ReactElement<TabProps>) => (
    <Tabitem
      activeKey={props.activekey}
      onClick={props.onSelect}
      tabKey={child.props.tabKey}
      title={child.props.title}
    />
  );

  const renderTabContent = (child: React.ReactNode, parentKey: string) => {
    return parentKey === props.activekey ? child : null;
  };

  return (
    <Responsive>
      <div className={`tabs ${props.className || ''}`}>
        <div className='tabs__header'>
          {React.Children.map(props.children, child =>
            React.isValidElement(child) ? renderTabItem(child) : null
          )}
        </div>
        <div className='tabs__content' data-active-key={props.activekey}>
          {React.Children.map(props.children, child =>
            React.isValidElement(child) && child.props.children
              ? renderTabContent(child.props.children, child.props.tabKey)
              : null
          )}
        </div>
      </div>
    </Responsive>
  );
};

interface TabProps {
  title: string | React.ReactElement;
  tabKey: string;
}

export const Tab = (props: React.PropsWithChildren<TabProps>) => {
  throw new Error(
    'Tab component is not meant to be rendered outside Tabs component!'
  );
};

const Tabitem = ({
  title,
  tabKey,
  activeKey,
  onClick
}: {
  title: string | React.ReactElement;
  tabKey: string;
  activeKey: string;
  onClick(key: string): void;
}) => (
  <div
    onClick={() => onClick(tabKey)}
    className={`tabitem ${activeKey === tabKey ? 'active' : ''}`}
  >
    {title}
  </div>
);
