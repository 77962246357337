import React from 'react';

import './MenuButton.scss';

export const MenuButton = ({
  open,
  className,
  onClick
}: {
  open: boolean;
  className: string;
  onClick(): void;
}) => (
  <div
    className={`menu-button ${className} ${open ? 'open' : ''}`}
    onClick={onClick}
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
);
