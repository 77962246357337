import React from 'react';

import { useTranslation } from 'react-i18next';

import { Row } from './Flex';

import './FileDrop.scss';

export interface FileDropProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  file?: string;
  multiple?: boolean;
  onDropFile(file: File): void;
  onDropMultipleFiles?(file: FileList): void;
}

export const FileDrop = ({
  onDropFile,
  onDropMultipleFiles,
  file,
  className,
  children,
  multiple,
  ...nativeProps
}: React.PropsWithChildren<FileDropProps>) => {
  const { t } = useTranslation('common');

  const dropRef = React.useRef<HTMLDivElement | null>(null);

  const [drag, setDrag] = React.useState(false);

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length === 1)
      setDrag(true);
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files) {
      if (e.dataTransfer.files.length === 1) {
        onDropFile(e.dataTransfer.files[0]);
        e.dataTransfer.clearData();
      } else if (onDropMultipleFiles) {
        onDropMultipleFiles(e.dataTransfer.files);
      }
    } 
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files.length === 1) {
        onDropFile(e.target.files[0]);
      } else if (onDropMultipleFiles) {
        onDropMultipleFiles(e.target.files);
      }
    } 
  };

  return (
    <Row
      //@ts-ignore
      ref={dropRef}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={`file-drop ${drag ? 'dragging' : ''} ${
        file ? 'has-file' : ''
      } ${className || ''}`}
      {...nativeProps}
    >
      <input type='file' className='hidden-input' multiple={multiple} onChange={handleFileInput} />
      {children ? (
        children
      ) : file && !drag ? (
        <div className='file-drop-filename'>{file}</div>
      ) : (
        <div className='file-drop-hint'>
          {t('common:components.FileDrop.hint')}
        </div>
      )}
    </Row>
  );
};
