import React from 'react';

import { useSelector } from 'react-redux';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

import { ButtonWithIcon } from './Button';
import { Column } from './Flex';

import { isEqualOrHigherTierByKey } from 'domain/utils';

import { UserRole, AppStore } from 'domain/models';

import './WithRole.scss';

export interface WithRoleProps {
  role: UserRole['role'];
  exact?: boolean;
  action?: 'hide' | 'disable' | 'destroy';
  showOverlay?: boolean;
}

export const WithRole = React.forwardRef<
  any,
  React.PropsWithChildren<WithRoleProps>
>(
  (
    {
      action = 'hide',
      exact = false,
      showOverlay = false,
      role,
      children
    }: React.PropsWithChildren<WithRoleProps>,
    ref: any
  ) => {
    const userRoles = useSelector((store: AppStore) => store.auth.user?.roles);

    if (isEqualOrHigherTierByKey(userRoles || 'ROLE_USER', role, exact)) {
      return children;
    } else if (showOverlay) {
      return React.Children.map(children, (child: any, index: number) =>
        React.cloneElement(child, {
          children: child.props.children
            ? child.props.children.concat(
                UpgradeOverlay({ targetTier: role, key: index.toString() })
              )
            : [UpgradeOverlay({ targetTier: role, key: index.toString() })]
        })
      );
    } else {
      switch (action) {
        case 'destroy':
          return null;
        case 'disable':
          return React.Children.map(children, (child: any) =>
            React.cloneElement(child, {
              className: `${child.className || ''} disabled-by-tier`
            })
          ) as any;
        case 'hide':
        default:
          return React.Children.map(children, (child: any) =>
            React.cloneElement(child, {
              style: { visibility: 'hidden' }
            })
          ) as any;
      }
    }
  }
);

const UpgradeOverlay = ({
  targetTier,
  key
}: {
  targetTier: UserRole['role'];
  key: string;
}) => (
  <Column className='upgrade-overlay' key={key}>
    <span>This content is only available for tier {targetTier} </span>
    <ButtonWithIcon icon={faArrowUp}>
      Upgrade to {targetTier} tier
    </ButtonWithIcon>
  </Column>
);
