import React from 'react';

import ReactDateTimePicker, { ReactDatePickerProps } from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { Row, Column } from './Flex';
import { UI } from './Icons';
import { H } from './Heading';

import { mergeRefs } from 'domain/utils';

import 'react-datepicker/dist/react-datepicker.css';

import './Input.scss';
import './DateTimePicker.scss';

export const DateTimePicker = React.forwardRef(
  ({ className, ...props }: ReactDatePickerProps, ref: any) => {
    const { t } = useTranslation('common');

    const datePickerRef = React.useRef<ReactDateTimePicker>(null);
    const tooltipId = React.useRef<string>(
      `date-picker-tooltip-${Date.now() + Math.random() * 100}`
    );

    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (datePickerRef.current) {
        switch (e.keyCode) {
          case 9: {
            datePickerRef.current.setOpen(false);
            datePickerRef.current.setBlur();
          }
        }
      }
    };

    return (
      <Row className='date-picker-wrapper'>
        <ReactDateTimePicker
          ref={mergeRefs(ref, datePickerRef)}
          showPopperArrow={false}
          className={`date-picker input ${className}`}
          onKeyDown={onKeyPress}
          {...props}
        />
        <UI.CalendarIcon
          className='calendar-icon'
          data-tip
          data-for={tooltipId.current}
        />
        <ReactTooltip id={tooltipId.current} place='bottom' effect='solid' className='tooltip'>
          <H.H2 className='key-bindings-title'>
            {t('common:components.ReactDatePicker.tooltip.title')}
          </H.H2>
          <Column className='key-bindings-container'>
            {(t('common:components.ReactDatePicker.tooltip.bindings', {
              returnObjects: true,
            }) as string[]).map((hint) => (
              <span className='key-binding' key={hint}>
                {hint}
              </span>
            ))}
          </Column>
        </ReactTooltip>
      </Row>
    );
  }
);
