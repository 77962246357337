import { AuthorizedService } from './authorized-service.base';
import { CpuQuota, QuotaGranularity } from 'domain/models';
import { Api } from 'domain/core';

export class QuotaService extends AuthorizedService {
  public getCpuQuota(granularity: QuotaGranularity): Promise<CpuQuota[]> {
    //return fetch('/__mock__/cpu-quota.json').then((r) => r.json());
    return this.fetch(
      `${Api.baseUrl}${Api.quota.cpu}/${granularity}`
    ).then((r) => r.json());
  }

  public getDiskQuota(granularity: QuotaGranularity): Promise<CpuQuota[]> {
    return this.fetch(
      `${Api.baseUrl}${Api.quota.disk}/${granularity}`
    ).then((r) => r.json());
  }
}
