import { createActionCreator } from 'deox';
import { Token, User, UserPlanDto } from 'domain/models';

const LOGIN_SUCCESS = '[Auth] :: Login success';

const SET_AVATAR = '[Auth] :: Set Avatar'

const SET_PROFILE = '[Auth] :: Set Current User Profile';

const LOGOUT = '[Auth] :: Register';

const REFRESH_SUCCESS = '[Auth] :: Refresh session success';

const SET_CAPABILITIES = '[Auth] :: Set user capabilities';

export const loginSuccess = createActionCreator(
  LOGIN_SUCCESS,
  (resolve) => (token: Token) => resolve(token)
);

export const logout = createActionCreator(LOGOUT, (resolve) => () => resolve());

export const refreshSuccess = createActionCreator(
  REFRESH_SUCCESS,
  (resolve) => (response: Token) => resolve(response)
);

export const setAvatar = createActionCreator(
  SET_AVATAR,
  (resolve) => (avatarSrc: string) => resolve(avatarSrc)
);

export const setProfile = createActionCreator(
  SET_PROFILE,
  (resolve) => (profile: User) => resolve(profile)
);

export const setCapabilities = createActionCreator(
  SET_CAPABILITIES,
  (resolve) => (capabilities: UserPlanDto) => resolve(capabilities)
);
