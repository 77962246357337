import { createReducer } from 'deox';

import * as Actions from './root.actions';

import { RootStore } from 'domain/models';

export const initialState: RootStore = {
  alerts: [],
  activeAction: null,
};

export const rootReducer = createReducer(initialState, (handle) => [
  handle(Actions.pushAlert, (state, { payload }) => ({
    ...state,
    alerts: state.alerts.concat(
      Object.freeze({ ...payload, innerKey: Symbol(Date.now().toString()) })
    ),
  })),
  handle(Actions.removeAlert, (state, { payload }) => ({
    ...state,
    alerts: state.alerts.filter((a) => a.innerKey !== payload),
  })),
  handle(Actions.triggerAction, (state, { payload }) => ({
      ...state,
      activeAction: payload
  })),
]);
