import { AuthorizedService } from './authorized-service.base';
import {
  Experiment,
  ExperimentFields,
  ExperimentForm,
  ExperimentApiModel,
  RestQueryExtras,
  ExperimentAvailable,
  ExperimentAvailableQuery,
  ExperimentFileMetaData,
} from 'domain/models';
import { Api, Defaults } from 'domain/core';
import { toPaginationQuery, toUTC } from 'domain/utils';

export class ExperimentsService extends AuthorizedService {
  public getExperiments(
    restExtras: RestQueryExtras = Defaults.defaultRestExtras
  ): Promise<Experiment[]> {
    return this.fetch(
      Api.baseUrl + Api.experiment.base + toPaginationQuery(restExtras)
    ).then((r) => r.json());
  }

  public getExperiment(experimentId: number): Promise<Experiment> {
    return this.fetch(
      Api.baseUrl + Api.experiment.base + '/' + experimentId
    ).then((r) => {
      if (r.status === 200) {
        return r.json();
      } else {
        return r.json().then((err) => {
          throw err;
        });
      }
    });
  }

  public getDemoExperiment(): Promise<Experiment> {
    return fetch('/data/demo_experiment.json').then((res) => res.json());
  }

  public count(): Promise<{ count: number }> {
    return this.fetch(
      `${Api.baseUrl}${Api.experiment.base}${Api.experiment.count}`
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return Promise.resolve({ count: 0 });
        }
      })
      .catch(() => {
        Promise.resolve({ count: 0 });
      });
  }

  public getGeoJSON(experimentId: string): Promise<string | void | null> {
    return this.fetch(
      `${Api.baseUrl}${Api.experiment.base}/${experimentId}${Api.experiment.geojson}`
    )
      .then((res) => {
        if (res.status === 200) {
          return res.text();
        } else {
          return Promise.resolve(null);
        }
      })
      .catch(() => {
        Promise.resolve(null);
      });
  }

  /**
   * Get possible experiments fields values
   */
  public async getExperimentsFields(): Promise<ExperimentFields> {
    return await this.fetch(
      Api.baseUrl + Api.experiment.base + Api.experiment.fields
    )
      .then((r) => r.json())
      .then((json) => {
        // Parse methods variables
        json.methods.map((method: any) => {
          return method;
        });

        return json;
      });
  }

  public async getFileLink(id: string, type: string) : Promise<string> {
    const url = `${Api.baseUrl}${Api.experiment.base}/${id}/downloadToken`;
    return this.fetch(url, {
      method: 'GET',
    }).then(async (response) => {
      if (response.status === 200) {
        const token = (await response.json()).token
        return `${Api.baseUrl}${Api.experiment.static}/${id}/${type}?token=${token}`;
      } else {
        const err : any = new Error();
        err.json = await response.json();
        throw err;
      }
    });
  }

  public async getFile(id: string, experimentFile: string, onProgress?: Function) : Promise<Blob> {
    const url = `${Api.baseUrl}${Api.experiment.base}/${id}${experimentFile}`;
    if (window.ReadableStream) {
      const metadata = await this.getFileMetadata(id, experimentFile);
      const resp = await this.fetch(url, { method: 'GET' });
      const res = new Response(new ReadableStream({
        async start(ctrl) {
          const reader = resp.body!.getReader();
          let downloaded = 0;
          while(true) {
            const readerRead = (await reader.read());
            if (readerRead.done) {
              break;
            }
            downloaded += readerRead.value.byteLength;
            if (onProgress) {
              onProgress(downloaded / metadata.size)
            }
            ctrl.enqueue(readerRead.value);

          }
          ctrl.close();
        }
      }), resp)
      return await res.blob();
    }
    /* Fallback */
    return this.fetch(url, {
      method: 'GET',
    }).then(async (response) => {
      if (response.status === 200) {
        return response.blob();
      } else {
        const err : any = new Error();
        err.json = await response.json();
        throw err;
      }
    });
  }

  public async getFileMetadata(id: string, experimentFile: string) : Promise<ExperimentFileMetaData> {
    const url = `${Api.baseUrl}${Api.experiment.base}/${id}${experimentFile}/metadata`;
    return this.fetch(url, {
      method: 'GET',
    }).then(async (response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        const err : any = new Error();
        err.json = await response.json();
        throw err;
      }
    });
  }

  public remove(id: string): Promise<any> {
    return this.fetch(`${Api.baseUrl}${Api.experiment.base}/${id}`, {
      method: 'DELETE',
    }).then((r) => r.ok);
  }

  public async queryAvailable(
    query: ExperimentAvailableQuery
  ): Promise<ExperimentAvailable> {
    let url = `${Api.baseUrl}${Api.experiment.base}${Api.experiment.availableQuery}?`;
    /* Query parameters */
    Object.entries(query).forEach((kvarray: any[]) => {
      const key: string = kvarray[0];
      const value: string = kvarray[1];
      if (value !== null && value !== undefined) {
        url += `${key}=${value.toString()}&`;
      }
    });
    return this.fetch(url, {
      method: 'GET',
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error();
      }
    });
  }

  public async saveExperiment(experimentForm?: ExperimentForm): Promise<any> {
    if (!experimentForm) {
      throw new Error();
    }

    const experiment = experimentFormToExperimentApiModel(experimentForm);

    try {
      const createExperimentData: any = await this.fetch(
        Api.baseUrl + Api.experiment.base,
        {
          method: 'POST',
          body: JSON.stringify(experiment),
        }
      ).then((response) => {
        if (response.status === 201) {
          return response.json();
        } else {
          // If status code is not 201 Created, throw error via promise
          return new Promise(async (res, rej) => {
            try {
              const json = await response.json();
              rej(json.msg);
            } catch (e) {
              /* JSON Exception */
              rej();
            }
          });
        }
      });
      return { id: createExperimentData.id.toString(), status: 'CREATED' };
    } catch (errMsg) {
      return { msg: errMsg, status: 'ERROR' };
    }
  }

  public async getExperimentEstimation(
    experimentForm: ExperimentForm
  ): Promise<any> {
    const experiment = experimentFormToExperimentApiModel(experimentForm);

    try {
      return this.fetch(
        Api.baseUrl + Api.experiment.base + Api.experiment.estimation,
        {
          method: 'POST',
          body: JSON.stringify(experiment),
        }
      ).then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response;
        }
      });
    } catch (errMsg) {
      return { msg: errMsg, status: 'ERROR' };
    }
  }
}

const experimentFormToExperimentApiModel = (
  experimentForm: ExperimentForm
): ExperimentApiModel => {
  // Convert experimentForm to
  return {
    name: experimentForm.name,
    mask: experimentForm.landMask ? 'land' : 'none',
    spatialCoverage: experimentForm.spatialCoverage,
    configuration: {
      variables: experimentForm.variables.map((variable) => variable.name),
      models: experimentForm.models.map((m) => m.projection.name),
      scenarios: experimentForm.scenarios.map((scenario) => ({
        name: scenario.name,
        startDate: toUTC(scenario.startDate),
        endDate: toUTC(scenario.endDate),
      })),
    },
    datasetProjection: { id: experimentForm.datasetProjection?.id },
    datasetReference: { id: experimentForm.datasetReference?.id },
    startDate: experimentForm.startDate.getTime(),
    endDate: experimentForm.endDate.getTime(),
    baconfiguration: experimentForm.bAConfiguration.map((bAVariable) => {
      return {
        variable: bAVariable.variable.name,
        method: bAVariable.method.name,
        parameters: bAVariable.parameters,
      };
    }),
    spatialResolutionAggregationFunctions:
      experimentForm.experimentOutputData
        ?.spatialResolutionAggregationFunctions || {},
    temporalResolutionAggregationFunctions:
      experimentForm.experimentOutputData
        ?.temporalResolutionAggregationFunctions || {},
    spatialResolution: experimentForm.experimentOutputData?.spatialResolution?.toUpperCase(),
    temporalResolution: experimentForm.experimentOutputData?.temporalResolution?.toUpperCase(),
    outputFormat: experimentForm.experimentOutputData?.outputFormat?.toUpperCase(),
    geojson: experimentForm.experimentOutputData?.geojson || null,
    validation: experimentForm.validationLevel,
  };
};
