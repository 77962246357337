import React from 'react';
import ReactGA from 'react-ga4';

export const usePageView = () => {
  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    console.info(
      'Send analytics pageView event for ',
      window.location.pathname
    );
  }, []);
};
