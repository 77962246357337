import React from 'react';

import './Table.scss';

export type TableProps = React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
>;
const TTable = React.forwardRef<any, React.PropsWithChildren<TableProps>>(
  (props: React.PropsWithChildren<TableProps>, ref: any) => {
    const { className, children, ...otherProps } = props;

    return (
      <table className={`table ${className || ''}`} {...otherProps} ref={ref}>
        {children}
      </table>
    );
  }
);

export type TableBodyProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;
const TTableBody = React.forwardRef<
  any,
  React.PropsWithChildren<TableBodyProps>
>((props: React.PropsWithChildren<TableBodyProps>, ref: any) => {
  const { className, children, ...otherProps } = props;

  return (
    <tbody className={`tbody ${className || ''}`} {...otherProps} ref={ref}>
      {children}
    </tbody>
  );
});

export type TableHeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;
const TTableHeader = React.forwardRef<
  any,
  React.PropsWithChildren<TableHeaderProps>
>((props: React.PropsWithChildren<TableHeaderProps>, ref: any) => {
  const { className, children, ...otherProps } = props;

  return (
    <thead className={`thead ${className || ''}`} {...otherProps} ref={ref}>
      {children}
    </thead>
  );
});

export type TRowProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>;
const TRow = React.forwardRef<any, React.PropsWithChildren<TRowProps>>(
  (props: React.PropsWithChildren<TRowProps>, ref: any) => {
    const { className, children, ...otherProps } = props;

    return (
      <tr className={`tr ${className || ''}`} {...otherProps} ref={ref}>
        {children}
      </tr>
    );
  }
);

export type TCellProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>;
const TCell = React.forwardRef<any, React.PropsWithChildren<TCellProps>>(
  (props: React.PropsWithChildren<TCellProps>, ref: any) => {
    const { className, children, ...otherProps } = props;

    return (
      <td className={`td ${className || ''}`} {...otherProps} ref={ref}>
        {children}
      </td>
    );
  }
);

export type THeadProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableHeaderCellElement>,
  HTMLTableHeaderCellElement
>;
const THead = React.forwardRef<any, React.PropsWithChildren<THeadProps>>(
  (props: React.PropsWithChildren<THeadProps>, ref: any) => {
    const { className, children, ...otherProps } = props;

    return (
      <th className={`th ${className || ''}`} {...otherProps} ref={ref}>
        {children}
      </th>
    );
  }
);

interface TAutoProps {
  data: any[];
  headers: {key: string, label: string}[];
  rowId: string;
  rowSelectedKey?: string;
  onRowSelect(row: any): void;
  translate?(key: string, value: string): string;
}
const TAuto = React.forwardRef((props: TAutoProps, ref?: any) => {
  const {translate = (key: string, value: string) => value} = props;
  return (
    <TTable ref={ref}>
      <TTableHeader>
        <TRow>
          {props.headers.map(({key, label}) => (
            <THead key={key}>{label}</THead>
          ))}
        </TRow>
      </TTableHeader>
      <TTableBody>
        {props.data.map((d: any) => (
          <TRow
            key={d[props.rowId]}
            onClick={() => props.onRowSelect(d)}
            className={
              props.rowSelectedKey === d[props.rowId] ? 'selected' : ''
            }
          >
            {props.headers.map(({key}) => (
              <TCell key={key}>{translate(key, d[key])}</TCell>
            ))}
          </TRow>
        ))}
      </TTableBody>
    </TTable>
  );
});

export const Table = {
  Auto: TAuto,
  T: TTable,
  Body: TTableBody,
  Header: TTableHeader,
  Row: TRow,
  Cell: TCell,
  CellHead: THead
};
