import React from 'react';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Column, Row, Responsive } from 'components';

import './Stepper.scss';

export type Step = {
  title: string;
  code: string;
  completed: boolean;
};

export interface StepperProps {
  steps: Step[];
  selectedStep: Step;
  i18nNs: string[];
  onStepSelected?(step: Step): void;
}
export const Stepper = (props: StepperProps) => {
  const { t } = useTranslation(props.i18nNs);

  return (
    <Responsive>
      <Column className='stepper'>
        {props.steps.map((step: Step, index: number) => (
          <Row
            className={`step ${
              props.selectedStep.code === step.code ? 'selected' : ''
            } ${step.completed ? 'completed' : ''}`}
            key={step.code}
            onClick={() => props.onStepSelected && props.onStepSelected(step)}
          >
            {step.completed ? (
              <FontAwesomeIcon icon={faCheck} className='complete-icon' />
            ) : (
              <Row className='step-index'>{index + 1}</Row>
            )}
            <span className='step-name'>{t(step.title)}</span>
          </Row>
        ))}
      </Column>
    </Responsive>
  );
};
