import { AuthorizedService } from './authorized-service.base';
import { Api, Defaults } from 'domain/core';
import {
  ApiKey,
  RestQueryExtras,
} from 'domain/models';
import { toPaginationQuery } from 'domain/utils';

export class ApiKeyService extends AuthorizedService {
  public async getAll(
    extras: RestQueryExtras = Defaults.defaultRestExtras
    ): Promise<ApiKey[]> {
    return await this.fetch(
      Api.baseUrl +
        Api.auth.apiKey +
        toPaginationQuery(extras)
    ).then((r) => r.json());
  }
  
  public async create(): Promise<ApiKey> {
    return await this.fetch(
      Api.baseUrl + Api.auth.apiKey,
      {
        method: 'POST'
      }
    ).then((res: Response) => res.json());
  }

  public async updateState(id: number, enabled: boolean): Promise<boolean> {
    return await this.fetch(
      Api.baseUrl + Api.auth.apiKey + `/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ enabled }),
      }
    ).then((res: Response) => res.status === 204);
  }

  public async delete(id: number): Promise<any> {
    return await this.fetch(
      Api.baseUrl + Api.auth.apiKey + `/${id}`,
      {
        method: 'DELETE',
      }
    ).then((res: Response) => res.status === 204);
  }
}
