import {
  AddressFormData,
  LoginFormData,
  AboutRegisterFormData,
  CompanyRegisterFormData,
  UserBillingFormData,
  ChangePasswordFormData,
  ChangePersonalFormData,
  ChangeCompanyFormData,
  AdminEditUserForm,
  PageInfo,
  UploadDatasetBasicFormData,
  PasswordRegisterFormData,
  PricingRegisterFormData,
  VariableMapping,
  NewSupportTicketFormData,
  RestQueryExtras,
  NotificationForm,
  NotificationSearchFields,
  UserSearchFields,
  DatasetSearchFields,
  ExperimentSearchFields,
  ExperimentOutputData,
  AdminQuestionForm,
} from 'domain/models';

export const defaultAddressFormData: AddressFormData = {
  line1: '',
  line2: '',
  line3: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};

export const defaultPricingRegistrationFormData: PricingRegisterFormData = {
  type: 'free',
};

export const defaultLoginFormData: LoginFormData = {
  password: '',
  email: '',
};

export const defaultAboutRegistrationFormData: AboutRegisterFormData = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  termAndConditions: false,
  newsletter: false,
};

export const defaultExperimentOutputData: ExperimentOutputData = {
  spatialResolutionAggregationFunctions: {},
  temporalResolutionAggregationFunctions: {},
  spatialResolution: '',
  temporalResolution: '',
  geojson: null,
  outputFormat: '',
};

export const defaultPasswordRegistrationFormData: PasswordRegisterFormData = {
  password: '',
  confirmPassword: '',
};

export const defaultCompanyRegistrationFormData: CompanyRegisterFormData = {
  address_line_1: '',
  address_line_2: '',
  address_city: '',
  address_province: '',
  address_zip: '',
  company: '',
  userType: 'ORGANIZATION',
  companyVat: '',
  position: '',
};

export const defaultBillingFormData: UserBillingFormData = {
  address: defaultAddressFormData,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
};

export const defaultChangePersonalFormData: ChangePersonalFormData = {
  firstName: '',
  lastName: '',
  phone: '',
};

export const defaultChangePasswordFormData: ChangePasswordFormData = {
  password: '',
  newPassword: '',
  confirmPassword: '',
};

export const defaultCompanyFormData: ChangeCompanyFormData = {
  address_line_1: '',
  address_line_2: '',
  address_city: '',
  address_province: '',
  address_zip: '',
  company: '',
  companyVat: '',
  position: '',
};

export const defaultAdminEditUserFormData: AdminEditUserForm = {
  firstName: '',
  lastName: '',
  processingUnitQuota: '',
  email: '',
  roles: [],
};

export const defaultUserSearchFields: UserSearchFields = {
  firstName: '',
  lastName: '',
  email: '',
};

export const defaultDatasetSearchFields: DatasetSearchFields = {
  name: '',
  from: '',
  to: '',
};

export const defaultExperimentSearchFields: ExperimentSearchFields = {
  name: '',
  status: '',
  from: '',
  to: '',
};

export const defaultNotificationSearchFields: NotificationSearchFields = {
  user: '',
  date: '',
  kind: '',
};

export const defaultNotifcationsFormData: NotificationForm = {
  title: '',
  notification: '',
  userType: 'ALL',
};

export const defaulUploadDatasetFormData: UploadDatasetBasicFormData = {
  name: '',
  license: '',
  description: '',
};

export const defaultVariableFormData: VariableMapping = {
  customName: '',
  customUnit: '',
  standardName: '',
  standardUnit: '',
};

export const defaultWithFormConfig = {
  mode: 'onBlur',
  submitFocusError: true,
} as const;

export const licenses = ['gnu-gplv3', 'mozilla', 'apache', 'mit', 'unlicensed'];
export const datasetFormats = ['GRID', 'POINTS'];
export const datasetKinds = ['PUBLIC', 'PRIVATE'];
export const variables = [
  {
    code: 'tas',
    units: ['Kelvin', 'Celsius', 'Fahrenheit'],
  },
  {
    code: 'pr',
    units: ['mm-day-1', 'mm', 'mm s-1', 'kg m-2 day-1', 'kg m-2'],
  },
  {
    code: 'sfcWind',
    units: ['m s-1', 'km h-1', 'mph'],
  },
  {
    code: 'tasmax',
    units: ['Kelvin', 'Celsius', 'Fahrenheit'],
  },
  {
    code: 'tasmin',
    units: ['Kelvin', 'Celsius', 'Fahrenheit'],
  },
  {
    code: 'hurs',
    units: ['%'],
  },
  {
    code: 'rsds',
    units: ['W m-2'],
  }
];

export const temporalResolutions = ['daily', 'hourly'];

export const metrics = [
  'average',
  'length_of_valid_values',
  'missing_data',
  'standard_variance',
];

export const supportTicketCategory: NewSupportTicketFormData['category'][] = [
  'experiments',
  'general',
  'datasets',
  'payment',
  'plans',
  'increment_quota',
  'publish_input_data',
  'plan_upgrade',
];

export const freeSupportTicketCategory: NewSupportTicketFormData['category'][] = [
  'plan_upgrade',
];

export const freeDefaultNewSupportTicketFormData: NewSupportTicketFormData = {
  title: '',
  category: freeSupportTicketCategory[0], // plan_upgrade
  textContent: '',
};

export const defaultNewSupportTicketFormData: NewSupportTicketFormData = {
  title: '',
  category: supportTicketCategory[1], // general
  textContent: '',
};

export const defaultQuestionFormData: AdminQuestionForm = {
  daysLastQuestion: 5,
  deleted: false, 
  enabled: true,
  event: 'auth-login',
  key: '',
  maxRating: null,
  minRating: null,
  negativeTextPlaceholder: '',
  positiveTextPlaceholder: '',
  parentKey: null,
  question: '',
  repeatable: false,
  threshold: 3,
  type: 'BOTH',
  weight: 1,
};

export const defaultPageInfo: PageInfo = {
  number: 0,
  size: 10,
  totalElements: 0,
  totalPages: 1,
};

export const defaultRestExtras: RestQueryExtras = {
  page: 0,
  pageSize: 10,
  sort: '',
  sortDirection: 'ASC',
};
