import { createReducer } from 'deox';

import * as Actions from './auth.actions';
import { AuthStore } from 'domain/models';
import { AuthorizationService } from 'domain/services';

export const initialState: AuthStore = {
  loggedIn: false,
  user: undefined,
  capabilities: undefined
};

export const authReducer = createReducer(initialState, (handle) => [
  handle(Actions.loginSuccess, (state, { payload }) => {
    return {
      ...state,
      loggedIn: true,
      token: payload,
    };
  }),

  handle(Actions.refreshSuccess, (state, { payload }) => {
    return { ...state, token: payload };
  }),

  handle(Actions.setAvatar, (state, { payload }) => {
    return { ...state, avatarSrc: payload };
  }),

  handle(Actions.logout, (state) => {
    AuthorizationService.removeAuthorization();
    return {
      loggedIn: false,
      token: undefined,
      user: undefined,
    };
  }),

  handle(Actions.setProfile, (state, { payload }) => ({
    ...state,
    user: payload,
  })),

  handle(Actions.setCapabilities, (state, { payload }) => ({
    ...state,
    capabilities: payload,
  })),
]);
