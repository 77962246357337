import { AuthorizedService } from './authorized-service.base';
import { Api, Defaults } from 'domain/core';
import {
  Dataset,
  ValidationReport,
  VariableMapping,
  RestQueryExtras,
  DatasetInfo,
  DatasetState,
} from 'domain/models';
import { toPaginationQuery, argsToQuery } from 'domain/utils';


export class DatasetService extends AuthorizedService {
  public async getDatasetState(uploadId: number): Promise<DatasetState> {
    const url =
      Api.baseUrl + Api.datasets.state.replace('{{id}}', uploadId.toString());
    return await this.fetch(url, { method: 'GET' }).then((r) => r.json());
  }

  public async getDatasetInfo(id: string): Promise<DatasetInfo[] | null> {
    const url = Api.baseUrl + Api.datasets.info.replace('{{id}}', id);
    return await this.fetch(url, { method: 'GET' }).then((r) => {
      if (r.status === 200) {
        return r.json();
      } else {
        return new Promise((res, rej) => res(null));
      }
    });
  }

  public async discardDataset(uploadId: number): Promise<void> {
    return await this.fetch(Api.baseUrl + Api.datasets.base + `/${uploadId}`, {
      method: 'DELETE',
    }).then();
  }

  public async activeDataset(uploadId: number): Promise<void> {
    const payload = { state: 'ACTIVE' };
    const url =
      Api.baseUrl + Api.datasets.state.replace('{{id}}', uploadId.toString());
    return await this.fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then();
  }

  public async uploadDataset(
    dataset: Dataset,
    variables: VariableMapping[],
    file: File,
    onProgress?: (evt: ProgressEvent<EventTarget>) => void
  ): Promise<ValidationReport> {

    let pyUpFormat;
    const fileExtension = file.name.split('.').pop();
    switch(fileExtension) {
      case 'zip':
        pyUpFormat = 'value text' // Not a typo, is value text
        break;
      case 'txt':
      case 'csv':
        pyUpFormat = 'value text'
      default:
        pyUpFormat = 'NETCDF'
        break;
    }
    const formData = new FormData();
    formData.append('name', dataset.name);
    formData.append('license', dataset.license);
    formData.append('description', dataset.description);
    formData.append('format', pyUpFormat);
    formData.append('variables', JSON.stringify(variables));

    formData.append('file', file);
    let id: number;
    try {
      const res = await this.uploadFile(`${Api.baseUrl + Api.datasets.base}/upload`, formData, onProgress);
      id = res.id;
    } catch (err) {
      throw err;
    }

    if (!id) {
      throw new Error('Upload dataset failed');
    }

    const finalPromise = new Promise<any>((res, rej) => {
      let requestInProgress = false;
      let intervalId = setInterval(async () => {
        if (!requestInProgress) {
          requestInProgress = true;
          try {
            const state = await this.getDatasetState(id);
            console.log(state);
            if (state.state === 'HOMOGENIZATION_VALIDATED') {
              clearInterval(intervalId);
              res(Object.assign(JSON.parse(state.payload), { id }));
            } else if (['HOMOGENIZATION_FAILED', 'FAILED'].includes(state.state)) {
              clearInterval(intervalId);
              // If has payload, set pyup as error information source
              const report: ValidationReport = {
                errors_report: {
                  ERROR: [
                    { msg: 'error.default.msg' }, // Default error
                  ],
                  WARNING: [],
                },
                point_info: {},
                job_id: '',
                id: id,      // In order to discard
                status: state.state,
              };
              const err = {
                report: report,
              };
              if (state.payload) {
                const pyupPayload = JSON.parse(state.payload);
                if (pyupPayload.errors_report) {
                  err.report.errors_report = pyupPayload.errors_report;
                }
              }
              rej(err);
            }
          } catch (err) {
            rej(err);
            clearInterval(intervalId);
          }
          requestInProgress = false;
        }
      }, 3000);
    });

    return finalPromise;
  }

  public async getDataset(id: number): Promise<Dataset> {
    return await this.fetch(
      Api.baseUrl + Api.datasets.base + `/${id}`
    ).then((r) => r.json());
  }

  public async getDatasets(
    extras: RestQueryExtras = Defaults.defaultRestExtras,
    args?: any
  ): Promise<Dataset[]> {
    return await this.fetch(
      Api.baseUrl +
        Api.datasets.base +
        toPaginationQuery(extras) +
        argsToQuery(args)
    ).then((r) => r.json());
  }

  public async getDatasetsGeoJSON(id: number, wkt: string): Promise<any> {
    const queryUrl =
      Api.baseUrl + Api.datasets.base + `/${id}/points?spatialCoverage=${wkt}`;
    return await this.fetch(queryUrl).then((r) => {
      if (r.status !== 200) {
        throw new Error();
      }
      return r.json();
    });
  }

  public async getDatasetNearestpointsGeoJSON(id: number, wkt: string): Promise<any> {
    const queryUrl =
      Api.baseUrl + Api.datasets.base + `/${id}/nearestpoints?spatialCoverage=${wkt}`;
    return await this.fetch(queryUrl).then((r) => {
      if (r.status !== 200) {
        throw new Error();
      }
      return r.json();
    });
  }

  public updateDataset(dataset: Dataset): Promise<boolean> {
    return this.fetch(Api.baseUrl + Api.datasets.base + `/${dataset.id}`, {
      method: 'PUT',
      body: JSON.stringify(dataset),
    }).then((res) => res.status === 204);
  }
}
