import React from 'react';

import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector, useDispatch, batch } from 'react-redux';

import { FixedLoading } from './Loading';

import { AppStore } from 'domain/models';
import { AuthorizationService, AccountService, AvatarService } from 'domain/services';
import { isValid } from 'domain/utils';
import { loginSuccess, setProfile, setCapabilities, setAvatar } from 'domain/store/auth';

export const ProtectedRoute = ({
  children: ProtectedComponent,
  ...otherProps
}: RouteProps) => {
  const loggedIn = useSelector((root: AppStore) => root.auth.loggedIn);

  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = AuthorizationService.getAuthorization();
        const [valid] = isValid(token);
        if (valid) {
          const accountService = new AccountService();

          const [currentUser, capabilities] = await Promise.all([
            accountService.getCurrent(),
            accountService.getCapabilities(),
          ]);

          const avatarSrc = await new AvatarService().getAvatar(currentUser.email);

          batch(() => {
            dispatch(loginSuccess(token));
            dispatch(setProfile(currentUser));
            dispatch(setCapabilities(capabilities));
            dispatch(setAvatar(avatarSrc));
          });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    checkAuth().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...otherProps}
      render={({ location }: any) =>
        loading ? (
          <FixedLoading />
        ) : loggedIn === true && ProtectedComponent ? (
          ProtectedComponent
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};
