import { UserRole } from '../models';

export const Roles: UserRole[] = [
  {
    role: 'ROLE_USER',
    weight: 10,
  },
  {
    role: 'ROLE_TUTOR',
    weight: 50,
  },
  {
    role: 'ROLE_ADMIN',
    weight: 100,
  },
];
