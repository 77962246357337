import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute, FixedLoading } from 'components';

const Landing = React.lazy(
  () => import(/* webpackChunkName: "landing.page" */ 'pages/landing/Landing')
);

const Login = React.lazy(
  () => import(/* webpackChunkName: "auth.module" */ 'pages/auth/LoginPage')
);
const PaymentPage = React.lazy(
  () => import(/* webpackChunkName: "payment.module" */ 'pages/public/payment/PaymentPage')
);

const SignUp = React.lazy(
  () => import(/* webpackChunkName: "sign-up.module" */ 'pages/auth/SignUpPage')
);

const Verification = React.lazy(
  () =>
    import(
      /* webpackChunkName: "verification.module" */ 'pages/auth/VerificationPage'
    )
);

const Subscription = React.lazy(
  () =>
    import(
      /* webpackChunkName: "subscription.module" */ 'pages/auth/SubscriptionPage'
    )
);

const SubscriptionVerification = React.lazy(
  () =>
    import(
      /* webpackChunkName: "subscriptionverification.module" */ 'pages/auth/SubscriptionVerificationPage'
    )
);

const Unsubscribe = React.lazy(
  () =>
    import(
      /* webpackChunkName: "unsubscribe.module" */ 'pages/auth/UnsubscribePage'
    )
);

const RecoveryPassword = React.lazy(
  () =>
    import(/* webpackChunkName: "recovery.module" */ 'pages/auth/RecoveryPage')
);

const TermsOfService = React.lazy(
  () =>
    import(
      /* webpackChunkName: "terms-of-service.module" */ 'pages/documentation/TermsOfServicePage'
    )
);

const PrivacyPolicy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "privacy-policy.module" */ 'pages/documentation/PrivacyPolicyPage'
    )
);

const CookiePolicy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "cookie-policy.module" */ 'pages/documentation/CookiePolicyPage'
    )
);

const Blog = React.lazy(
  () => import(/* webpackChunkName: "blog.module" */ 'pages/blog/BlogLayout')
);

const BlogEntry = React.lazy(
  () =>
    import(/* webpackChunkName: "blog-entry.module" */ 'pages/blog/BlogEntry')
);

const RootApp = React.lazy(
  () => import(/* webpackChunkName: "app.root" */ 'pages/app/root/RootApp')
);

export const Router = () => {
  return (
    <React.Suspense fallback={<FixedLoading />}>
      <Switch>
        <Route path='/home'>
          <Landing />
        </Route>
        <Route path='/auth'>
          <Login />
        </Route>
        <Route path='/payment/:publicId' component={PaymentPage}>
        </Route>
        <Route path='/verification'>
          <Verification />
        </Route>
        <Route path='/subscription/verification'>
          <SubscriptionVerification />
        </Route>
        <Route path='/subscription/unsubscribe'>
          <Unsubscribe />
        </Route>
        <Route path='/subscription'>
          <Subscription />
        </Route>
        <Route path='/sign-up'>
          <SignUp />
        </Route>
        <Route path='/recovery'>
          <RecoveryPassword />
        </Route>
        <Route path='/terms-of-use'>
          <TermsOfService />
        </Route>
        <Route path='/privacy-policy'>
          <PrivacyPolicy />
        </Route>
        <Route path='/cookie-policy'>
          <CookiePolicy />
        </Route>
        <Route path='/blog/:id'>
          <BlogEntry />
        </Route>
        <Route path='/blog'>
          <Blog />
        </Route>
        <ProtectedRoute path='/app'>
          <RootApp />
        </ProtectedRoute>
        <Route path='/' exact>
          <Redirect to='/home' />
        </Route>
      </Switch>
    </React.Suspense>
  );
};
