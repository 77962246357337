import { useState, useEffect } from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}

const isBrowser = typeof window !== 'undefined';

function getScrollPosition(element: any): ScrollPosition {
  return isBrowser
    ? {
        x: element.pageXOffset || element.scrollLeft,
        y: element.pageYOffset || element.scrollTop,
      }
    : { x: 0, y: 0 };
}

export function useScrollPosition(el?: HTMLElement): ScrollPosition {
  const element = el || window;
  const [position, setScrollPosition] = useState<ScrollPosition>(
    getScrollPosition(element)
  );

  useEffect(() => {
    let requestRunning: number | null = null;
    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition(element));
          requestRunning = null;
        });
      }
    }

    if (isBrowser) {
      element.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        element.removeEventListener('scroll', handleScroll);
        if (requestRunning) {
          window.cancelAnimationFrame(requestRunning);
        }
      };
    }
  }, [element]);

  return position;
}

export function useScrollXPosition(el?: HTMLElement): number {
  const { x } = useScrollPosition(el);
  return x;
}

export function useScrollYPosition(el?: HTMLElement): number {
  const { y } = useScrollPosition(el);
  return y;
}
