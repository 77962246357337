import React from 'react'

import './MultiSelect.scss'
export interface MultiSelectProps {
  options: string[];
  selected: string[];
  onClick(value: string): void;
}

export const MultiSelect = (props: MultiSelectProps) => {
  const selectedNotIncluded = props.selected.filter(sel => !props.options.includes(sel));
  return (
    <div className="multi-item-select">
      {props.options.map(option => (
        <div className={"item " + ((props.selected.includes(option) ? "selected" : "") )}
          key={"item-" + option}
          onClick={() => props.onClick(option)}>{option}</div>
      ))}
      {selectedNotIncluded.map(option => (
        <div className={"item selected disabled"} key={"item-" + option}>{option}</div>
      ))}
    </div>)
}
