import React, { ChangeEvent } from 'react';

import ReactSelect, { Props } from 'react-select';

import { UI } from './Icons';

import { useIsMobile } from 'domain/hooks';

import './Select.scss';

export const Select = React.forwardRef(
  (props: Omit<Props, 'classNamePrefix'>, ref: any) => {
    const isMobile = useIsMobile();

    const { className = '', ...otherProps } = props;

    const nativeOnChange = (evt: ChangeEvent<HTMLSelectElement>) => {
      if (!props.onChange) {
        return;
      }
      const value = evt.target.value;
      if (props.options && props.options.length > 0) {
        const opt = props.options.find((o: any) => o.value === value);
        if (opt) {
          props.onChange({ value, label: opt.label });
        }
      }
      props.onChange({ value, label: '' });
    };

    return isMobile ? (
      <div className='mobile-select-wrapper'>
        <select
          ref={ref}
          className='select-mobile'
          value={props.value || props.defaultValue}
          onChange={nativeOnChange}
          name={props.name}
        >
          {otherProps.options.map(
            ({ value, label }: { value: any; label: string }) => (
              <option key={value} value={value}>
                {label}
              </option>
            )
          )}
        </select>
        <UI.PlayIcon className='chevron' />
      </div>
    ) : (
      <ReactSelect
        ref={ref}
        className={`select ${className}`}
        classNamePrefix='climadjust-select'
        {...otherProps}
      />
    );
  }
);
