import { tileLayer } from 'leaflet';
import { Mode } from '@revolut/checkout/types/types';

export const Api = {
  baseUrl: process.env.REACT_APP_BASE_API
    ? process.env.REACT_APP_BASE_API
    : process.env.NODE_ENV === 'development'
    ? 'https://test.climadjust.com'
    : window.location.origin,
  rest: '/rest',
  public: {
    base: '/public',
    contactUs: '/contact',
  },
  subscription: {
    subscribe: '/newsletter/subscribe',
    verification: '/newsletter/verification',
    unsubscribe: '/newsletter/unsubscribe',
  },
  auth: {
    login: '/auth/signin',
    renew: '/auth/renew',
    register: '/auth/signup',
    emailRecover: '/auth/recovery',
    verification: '/auth/verification',
    resetPassword: '/auth/password_reset',
    account: '/account',
    feedback: '/questions',
    avatar: '/avatar',
    summary: '/summary',
    apiKey: '/account/apikeys',
    capabilities: '/account/capabilities',
  },
  avatar: {
    base: '/avatar',
  },
  datasets: {
    base: '/datasets',
    state: '/datasets/upload/{{id}}/state',
    info: '/datasets/{{id}}/info',
  },
  methods: {
    base: '/methods',
    parameters: '/parameters',
  },
  payments: {
    base: '/payments',
    public: '/public/payments',
  },
  invoices: {
    base: '/invoices',
  },
  experiment: {
    base: '/experiments',
    fields: '/fields',
    count: '/count',
    availableQuery: '/available',
    validationFile: '/validation',
    resultFile: '/result',
    estimation: '/estimation',
    geojson: '/geojson',
    static: '/static/experiment',
  },
  support: {
    base: '/support',
    tutor: '/support/tutor',
    messages: '/messages',
    attachments: '/attachments',
  },
  notifications: {
    all: '/notifications/all',
    unread: '/notifications/unread',
    admin: '/admin/notifications',
    experiment: {
      base: '/admin/experimentnotifications',
    },
  },
  quota: {
    cpu: '/statistics/cpu',
    disk: '/statistics/disk',
  },
  blog: {
    base: '/blog',
    content: '/content',
    tags: '/tags',
  },
  admin: {
    users: '/admin/users',
    createUser: '/create',
    datasets: '/admin/datasets',
    experiments: '/admin/experiments',
    statistics: '/admin/users/statistics',
    planSubscriptions: '/admin/accounts/subscriptions',
    payments: '/admin/payments',
    invoices: {
      base: '/admin/invoices',
      custom: '/custom',
      id: '/id',
      pdf: '/pdf',
      month: '/month/invoice',
      charge: '/charge', // /admin/invoices/{{id}}/charge
    },
    userprofiles: {
      base: '/admin/userprofiles',
    },
    userplan: {
      base: '/rest/userplan',
    },
    blog: {
      base: '/admin/blog/content',
      image: '/admin/blog/content/images',
    },
    feedback: {
      statistics: '/admin/feedback/question/',
    },
  },
  revolutMode: (process.env.REACT_APP_REVOLUT_MODE
    ? process.env.REACT_APP_REVOLUT_MODE
    : process.env.NODE_ENV === 'development'
    ? 'sandbox'
    : 'prod') as Mode,
} as const;

export const BaseMap = (args: any = {}) =>
  tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 19,
    ...args,
  });

export const GaMeasurementID = 'G-B77V41XSKR';
export const RECAPTCHA_PUBLIC_KEY =
  process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY ||
  '6LdnUlIeAAAAADHOeyPDXalEtXMLOP5r2NepsF4I';
