import { GaMeasurementID } from 'domain/core/constants';

const disable = () => {
  (window as any)[`ga-disable-${GaMeasurementID}`] = true;
};

const enable = () => {
  delete (window as any)[`ga-disable-${GaMeasurementID}`];
};

export const AnalyticsService = {
  disable,
  enable,
};
