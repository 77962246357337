import { Subscriber, PageInfo } from 'domain/models';
import { AuthKetting } from '../ketting.auth';
import { UsersService } from './users.service';

export class NewsletterService extends AuthKetting {
  public async getSubscribers(
    page: PageInfo = this.defaultPage
  ): Promise<Subscriber[]> {
    const ketting = await this.getKetting();
    const subscribersResource = await ketting.follow('NewsletterUser', this.templatedPage(page))
      .follow('search', this.templatedPage(page))
      .follow<Subscriber[]>(
        'findByVerified',
        { verified: 'true' }
      );
    const subscribers = await subscribersResource.followAll<Subscriber>(
      'NewsletterUser'
    );

    return Promise.all(subscribers.map(subscriber => subscriber.get()));
  }

  public async getNewsletters(page: PageInfo = this.defaultPage): Promise<any> {
    const ketting = await this.getKetting();
    const newslettersResource = await ketting.follow(
      'NewsletterEmail',
      this.templatedPage(page)
    );

    const newsletters = await newslettersResource.followAll('NewsletterEmail');

    return Promise.all(newsletters.map(n => n.get()));
  }

  public async sendNewsletter(
    creator: string,
    content: string,
    subscribers: Subscriber[] | 'all'
  ): Promise<any> {
    const destinations =
      subscribers === 'all' ? [] : subscribers.map(s => s.email);
    const ketting = await this.getKetting();

    const fullCreator = await new UsersService().getUserByEmail(creator);
    const link = await fullCreator.link('self');
    return (await ketting.follow('NewsletterEmail', {})).post({
      creator: link.href,
      emailBody: content,
      destinations
    });
  }
}
