import React from 'react';

import './Gradients.scss';

export interface GradientsProps {
  className?: string;
}
export const Gradients = (props: GradientsProps) => {
  const canvas = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (canvas.current) {
        const ctx = canvas.current.getContext('2d') as CanvasRenderingContext2D;

        const maxHeight = window.innerHeight / 2;
        const maxWidth = window.innerWidth;

        canvas.current.style.height = `${maxHeight}px`;

        ctx!.canvas.height = maxHeight;
        ctx!.canvas.width = maxWidth;

        const width = canvas.current.getBoundingClientRect().width;
        const height = canvas.current.getBoundingClientRect().height;

        const blueGradient = ctx.createRadialGradient(
          0.1 * width,
          1.4 * height,
          width * 0.06,
          0.1 * width,
          1.4 * height,
          width * 0.4
        );
        blueGradient.addColorStop(0.3, '#68c3cdEE');
        blueGradient.addColorStop(0.5, '#83bbc288');
        blueGradient.addColorStop(0.9, '#FFFFFF00');

        ctx.fillStyle = blueGradient;
        ctx.fillRect(0, 0, width, height);

        const orangeGradient = ctx.createRadialGradient(
          0.7 * width,
          2 * height,
          width * 0.06,
          0.7 * width,
          2 * height,
          width * 0.6
        );
        orangeGradient.addColorStop(0.3, '#E87A46AA');
        orangeGradient.addColorStop(0.5, '#F3BD76AA');
        orangeGradient.addColorStop(0.9, '#FFFFFF00');

        ctx.fillStyle = orangeGradient;
        ctx.fillRect(0, 0, width, height);
      }
    }, 0);
  }, []);

  return (
    <div className={`gradients-container ${props.className || ''}`}>
      <div className='blur' />
      <canvas className='gradient' ref={canvas} />
    </div>
  );
};
