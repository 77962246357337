import React from 'react';

import { useTranslation } from 'react-i18next';

import { Step, Stepper } from './Stepper';
import { Row, Column } from './Flex';
import { BrandIcons, UI } from './Icons';
import { BackButton } from './Button';
import { ConfirmationModal } from './Modal';
import { H } from './Heading';

import { useIsMobile } from 'domain/hooks';

import './StepperModal.scss';

export interface StepperModalProps {
  steps: Step[];
  activeStep: Step;
  className?: string;
  title?: string;
  i18nNS: string[];
  onBackClick: VoidFunction;
  onCancel: VoidFunction;
  onStepSelected?(step: Step): void;
}
export const StepperModal = (
  props: React.PropsWithChildren<StepperModalProps>
) => {
  const { t } = useTranslation('common');

  const isMobile = useIsMobile();

  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const isFirstStep = React.useMemo(() => {
    const first = props.steps[0];
    return first.code === props.activeStep.code;
  }, [props.steps, props.activeStep]);

  return (
    <>
      <Row className={`stepper-modal ${props.className || ''}`}>
        <Column className='stepper-container'>
          <BrandIcons.Inline.Black
            width='140px'
            height='60px'
            style={{ position: 'absolute', zIndex: 10, cursor: 'pointer' }}
            title={t('common:actions.confirmation.stepper.iconTitle')}
            onClick={() => setShowConfirmation(true)}
          />
          <Stepper
            steps={props.steps}
            selectedStep={props.activeStep}
            i18nNs={props.i18nNS}
            onStepSelected={props.onStepSelected}
          />
        </Column>
        <Column className='content'>
          <Row className='stepper-modal-header'>
            <BackButton
              onClick={props.onBackClick}
              style={{ visibility: isFirstStep ? 'hidden' : 'visible' }}
            >
              {t('common:actions.back.label')}
            </BackButton>
            {!isMobile && (
              <H.H2 className='stepper-modal-header-title'>{props.title}</H.H2>
            )}
            <UI.CloseBigIcon
              className='close-icon'
              title={t('common:actions.confirmation.stepper.iconTitle')}
              onClick={() => {
                setShowConfirmation(true);
              }}
            />
          </Row>
          <Row className='scroll'>
            <Row className='container'>{props.children}</Row>
          </Row>
        </Column>
      </Row>
      <ConfirmationModal
        show={showConfirmation}
        title={t('common:actions.confirmation.stepper.title')}
        message={t('common:actions.confirmation.stepper.message')}
        onCancel={() => {
          setShowConfirmation(false);
        }}
        onConfirm={() => {
          props.onCancel();
          setShowConfirmation(false);
        }}
      />
    </>
  );
};
