import {
  LoginFormData,
  Token,
  AboutRegisterFormData,
  CompanyRegisterFormData,
  PasswordRegisterFormData,
  PricingRegisterFormData,
  SignUpResponse,
  ApiException,
} from 'domain/models';

import { Api } from '../core';

export class AuthorizationService {
  static getAuthorization(): Token {
    try {
      const auth = localStorage.getItem('auth:token');
      return JSON.parse(auth || '') as Token;
    } catch (err) {
      throw new Error(
        'AuthorizationService :: Unable to retrieve authorization!'
      );
    }
  }

  static saveAuthorization(token: Token) {
    localStorage.setItem('auth:token', JSON.stringify(token));
  }

  static removeAuthorization() {
    localStorage.removeItem('auth:token');
  }

  public login({ password, email }: LoginFormData): Promise<Token> {
    return fetch(Api.baseUrl + Api.auth.login, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: email,
        password,
      }),
    })
      .then(async (res: Response) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status >= 400 || res.status < 500) {
          let json = await res.json();
          throw json.msg;
        } else {
          throw 'error.internal';
        }
      })
      .then((token: Token) => {
        AuthorizationService.saveAuthorization(token);
        return token;
      });
  }

  public renew(): Promise<Token> {
    const token = AuthorizationService.getAuthorization();
    return fetch(Api.baseUrl + Api.auth.renew, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.token}`,
      },
    })
      .then((res: Response) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .then((token: Token) => {
        AuthorizationService.saveAuthorization(token);
        return token;
      });
  }

  public register(formData: PasswordRegisterFormData & AboutRegisterFormData):  Promise<boolean> {
    return fetch(Api.baseUrl + Api.auth.register, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
        phone: formData.phone,
        email: formData.email,
      }),
    })
      .then((res: Response) => {
        if (res.status === 201) {
          return true;
        }
        throw new Error();
      })
  }

  public sendRecoverEmail(email: string): Promise<number> {
    return fetch(`${Api.baseUrl}${Api.auth.emailRecover}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    }).then((r) => r.status);
  }

  public resetPassword(
    password: string,
    email: string,
    token: string
  ): Promise<{ status: number; error?: ApiException }> {
    return fetch(`${Api.baseUrl}${Api.auth.resetPassword}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password, email, token }),
    }).then(async (r) => {
      if (r.status !== 204) {
        return { status: r.status, error: await r.json() };
      }
      return { status: r.status };
    });
  }

  public userVerification(email: string, token: string): Promise<number> {
    return fetch(`${Api.baseUrl}${Api.auth.verification}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, token }),
    }).then((r) => r.status);
  }
}
