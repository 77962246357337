import { Api, Defaults } from 'domain/core';
import { BlogEntry, RestQueryExtras } from 'domain/models';
import { toPaginationQuery } from 'domain/utils';

export class BlogService {
  public getBlogEntries(
    restExtras: RestQueryExtras = Defaults.defaultRestExtras
  ): Promise<BlogEntry[]> {
    return fetch(
      `${Api.baseUrl}${Api.blog.base}${Api.blog.content}${toPaginationQuery({
        ...restExtras,
        sort: 'createdDate',
        sortDirection: 'DESC',
      })}`
    ).then((r) => r.json());
  }

  public getBlogEntry(blogId: string): Promise<BlogEntry> {
    return fetch(
      `${Api.baseUrl}${Api.blog.base}${Api.blog.content}/${blogId}`
    ).then((r) => r.json());
  }

  public getTags(): Promise<string[]> {
    return fetch(
      `${Api.baseUrl}${Api.blog.base}${Api.blog.content}${Api.blog.tags}`
    ).then((r) => {
      if (r.status.toString().startsWith('2')) {
        return r.json();
      }
      throw new Error();
    });
  }
}
