import { AuthorizedService } from './authorized-service.base';
import { Api } from 'domain/core';

export class AvatarService extends AuthorizedService {

  // Return url string
  getAvatar(username: string): Promise<string> {
    return this.fetch(`${Api.baseUrl}${Api.avatar.base}/${username}`)
      .then(r => r.blob())
      .then(blob => {
        if (blob.size === 0) {
          throw new Error('Avatar image size is 0')
        }
        return URL.createObjectURL(blob);
      }).catch(err => {
        return "/avatar.png"
      });
  }
  
  uploadAvatar(file: any): Promise<any> {
    return new Promise(async (res, rej) => {
      const validToken = await this.getValidToken();
      res(this.uploadAvatarToken(file, validToken.token))
    })
  }

  uploadAvatarToken(file: any, token: string): Promise<any> {
    const formData = new FormData();
    formData.append('avatar', file[0]);

    return fetch(`${Api.baseUrl}${Api.avatar.base}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    }).then(r => r.status === 201);
  }
}