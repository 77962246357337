import { UserRole, Token } from 'domain/models';
import { Roles } from 'domain/core';
import { batch } from 'react-redux';
import { AccountService, AvatarService } from 'domain/services';
import { setCapabilities, setProfile } from 'domain/store/auth';
import { Dispatch } from 'redux';

export const isEqualOrHigherTier = (
  userRole: UserRole,
  targetRole: UserRole
) => {
  return userRole.weight >= targetRole.weight;
};

export const isEqualOrHigherTierByKey = (
  userRoleKey: UserRole['role'] | UserRole['role'][],
  targetRoleKey: UserRole['role'],
  exact: boolean = false
): boolean => {
  const compareWeight = (intUserRole: UserRole['role']) => {
    if (exact) {
      return intUserRole === targetRoleKey;
    } else {
      const userRole = getRole(intUserRole);
      const targetRole = getRole(targetRoleKey);

      return userRole.weight >= targetRole.weight;
    }
  };

  if (Array.isArray(userRoleKey)) {
    return userRoleKey.some((key) => compareWeight(key));
  } else {
    return compareWeight(userRoleKey);
  }
};

export const getRole = (key: UserRole['role']): UserRole => {
  const role = Roles.find((r) => r.role === key);
  if (!role) {
    throw new Error(`Role ${key} not found!`);
  }
  return role;
};

export const isValid = (token: Token): [boolean, number] => {
  if (!token) {
    return [false, 0];
  }
  const date = new Date(token.validity);
  const msDate = date.valueOf();

  const msNow = Date.now();

  const delta = msDate - msNow;

  return [delta > 0, delta];
};

export const updateCapabilities = async (dispatch: Dispatch<any>) : Promise<any> => {
  const accountService = new AccountService();
  const [currentUser, capabilities] = await Promise.all([
    accountService.getCurrent(),
    accountService.getCapabilities(),
  ]);

  const avatarSrc = await new AvatarService().getAvatar(currentUser.email);

  batch(() => {
    dispatch(setProfile(currentUser));
    dispatch(setCapabilities(capabilities));
  });
}
