import React from 'react';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';

import { Row } from './Flex';
import { UI } from './Icons';

import './Input.scss';

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Input = React.forwardRef((props: InputProps, ref?: any) => {
  const { className, ...otherProps } = props;

  return (
    <input className={`input ${className || ''}`} ref={ref} {...otherProps} />
  );
});

export const InputWithErrors = React.forwardRef(
  (props: InputProps & { error?: string }, ref: any) => {
    const { error, className, ...otherProps } = props;

    return (
      <div className={`input-w-error ${error ? 'has-error' : ''}`}>
        <Input {...otherProps} ref={ref} />
        <CSSTransition
          timeout={200}
          unmountOnExit
          in={!!error}
          classNames='input-error-transition'
        >
          <span className='error'>
            <FontAwesomeIcon
              className='error-icon'
              icon={faExclamationCircle}
            />
            {error}
          </span>
        </CSSTransition>
      </div>
    );
  }
);

export const InputWithButton = React.forwardRef(
  (props: InputProps, ref: any) => {
    const { className, children, ...otherProps } = props;

    return (
      <div className='input-w-button'>
        <Input {...otherProps} className='input-w-button-input' ref={ref} />
        <Row className='icon-wrapper'>{children}</Row>
      </div>
    );
  }
);

export const PasswordInput = React.forwardRef(
  (props: InputProps & { error?: string }, ref: any) => {
    const { t } = useTranslation('common');

    const { className = '', ...otherProps } = props;

    const [revealPassword, setRevealPassword] = React.useState(false);

    return (
      <div className={`password-input-container ${className}`}>
        <InputWithErrors
          {...otherProps}
          ref={ref}
          type={revealPassword ? 'text' : 'password'}
        />
        <UI.EyeIcon
          className='icon'
          title={t(
            `common:components.PasswordInput.${
              revealPassword ? 'hidePassword' : 'revealPassword'
            }`
          )}
          onClick={() => setRevealPassword(!revealPassword)}
        />
      </div>
    );
  }
);

export const Checkbox = React.forwardRef(
  (props: Omit<InputProps, 'type'>, ref: any) => {
    const { className, ...otherProps } = props;

    return (
      <input
        type='checkbox'
        className={`checkbox ${className || ''}`}
        ref={ref}
        {...otherProps}
      />
    );
  }
);

export const Radio = React.forwardRef(
  (props: Omit<InputProps, 'type'>, ref: any) => {
    const { className, ...otherProps } = props;

    return (
      <input
        type='radio'
        className={`radio ${className || ''}`}
        ref={ref}
        {...otherProps}
      />
    );
  }
);
