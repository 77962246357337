import React from 'react';

import { useTranslation } from 'react-i18next';

import { Row } from './Flex';
import { UI } from './Icons';

import { range } from 'domain/utils';
import { PageInfo } from 'domain/models';

import './Pagination.scss';

const toRenderPages = (
  page: number,
  pages: number
): { toRender: number[]; prevEllipsis: boolean; nextEllipsis: boolean } => {
  let toRender, prevEllipsis, nextEllipsis;
  if (pages > 3) {
    if (page === 1) {
      toRender = range(3, 1);
      prevEllipsis = false;
      if (page + 3 < pages) {
        nextEllipsis = true;
      } else {
        nextEllipsis = false;
      }
    } else if (page === pages) {
      toRender = [pages - 2, pages - 1, pages];
      nextEllipsis = false;
      if (pages - 3 > 1) {
        prevEllipsis = true;
      } else {
        prevEllipsis = false;
      }
    } else {
      toRender = [page - 1, page, page + 1];
      prevEllipsis = true;
      nextEllipsis = true;
      if (page - 2 <= 1) {
        prevEllipsis = false;
      }

      if (page + 2 >= pages) {
        nextEllipsis = false;
      }
    }
  } else {
    toRender = range(pages, 1);
    prevEllipsis = false;
    nextEllipsis = false;
  }

  toRender = toRender.filter((n) => n !== 1 && n !== pages);

  return { toRender, prevEllipsis, nextEllipsis };
};

export type PaginationProps = PageInfo & {
  className?: string;
  onPageChange(page: number): void;
};

export const Pagination = (props: PaginationProps) => {
  const { number, totalPages, onPageChange } = props;

  const { toRender, prevEllipsis, nextEllipsis } = toRenderPages(
    number + 1,
    totalPages
  );

  const intOnPageChange = React.useCallback(
    (evtPage: 'prev' | 'next' | number) => {
      if (evtPage === number) {
        return;
      }

      if (evtPage === 'prev') {
        if (number > 1) {
          onPageChange(number - 1);
        }
      } else if (evtPage === 'next') {
        if (number < totalPages) {
          onPageChange(number + 1);
        }
      } else {
        onPageChange(evtPage);
      }
    },
    [number, totalPages, onPageChange]
  );

  return (
    <Row className={`pagination ${props.className}`}>
      <PrevButton
        className='arrow-button next'
        onClick={() => intOnPageChange('prev')}
      ></PrevButton>
      <PaginationButton
        active={number === 1}
        onClick={() => intOnPageChange(1)}
      >
        1
      </PaginationButton>
      {prevEllipsis && (
        <PaginationButton className='ellipsis' onClick={() => {}}>
          ...
        </PaginationButton>
      )}
      {toRender.map((n) => (
        <PaginationButton
          key={n}
          onClick={() => intOnPageChange(n)}
          active={number === n}
        >
          {n}
        </PaginationButton>
      ))}
      {nextEllipsis && (
        <PaginationButton className='ellipsis' onClick={() => {}}>
          ...
        </PaginationButton>
      )}
      {totalPages !== 1 && (
        <PaginationButton
          active={number === totalPages}
          onClick={() => intOnPageChange(totalPages)}
        >
          {totalPages}
        </PaginationButton>
      )}
      <NextButton
        className='arrow-button prev'
        onClick={() => intOnPageChange('next')}
      ></NextButton>
    </Row>
  );
};

type PaginationButtonProps = React.PropsWithChildren<{
  className?: string;
  active?: boolean;
  title?: string;
  onClick(evt: any): void;
}>;
const PaginationButton = ({
  className = '',
  active,
  onClick,
  title = '',
  children,
}: PaginationButtonProps) => (
  <Row
    title={title}
    className={`pagination-button ripple ${
      active ? 'active' : ''
    } ${className}`}
    onClick={onClick}
  >
    {children}
  </Row>
);

const PrevButton = (props: PaginationButtonProps) => {
  const { t } = useTranslation('common');

  return (
    <PaginationButton
      {...props}
      title={t('common:components.Pagination.prevPage')}
    >
      <UI.ArrowIcon className='prev-arrow' />
    </PaginationButton>
  );
};

const NextButton = (props: PaginationButtonProps) => {
  const { t } = useTranslation('common');

  return (
    <PaginationButton
      {...props}
      title={t('common:components.Pagination.nextPage')}
    >
      <UI.ArrowIcon className='next-arrow' />
    </PaginationButton>
  );
};
