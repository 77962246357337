import {
  PUConsumption,
  PUConsumptionEntry,
  RestQueryExtras,
  User,
} from 'domain/models';
import { map } from 'leaflet';

export const range = (length: number, from: number = 0): number[] =>
  Array.from(new Array(length), (_, i) => from + i);

export const assert = (
  condition: boolean,
  message: string = 'Assertion failed'
): void => {
  if (!condition) {
    throw new Error(message);
  }
};

export function minMax(arr: number[]) {
  return arr.reduce((mm: number[], val: number) => {
    if (val) {
      mm[0] =
        mm[0] === undefined || mm[0] === null || val < mm[0] ? val : mm[0];
      mm[1] =
        mm[1] === undefined || mm[1] === null || val > mm[1] ? val : mm[1];
    }
    return mm;
  }, []);
}

export const mergeRefs = (...refs: any[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export function throttle(
  callback: (...args: any[]) => any,
  limit: number
): (...args: any[]) => any {
  var waiting = false;
  return function (this: any, ...args: any) {
    if (!waiting) {
      callback.apply(this, args);
      waiting = true;
      setTimeout(function () {
        waiting = false;
      }, limit);
    }
  };
}

export function pluck<T>(arr: T[], key: keyof T): any[] {
  return arr.map((n: T) => n[key]);
}

export function uniq<T>(arr: T[]): T[] {
  return arr.filter(
    (e: T, index: number, self: T[]) => self.indexOf(e) === index
  );
}

export const sum = (arr: number[]): number => {
  return arr.reduce((acc: number, curr: number) => {
    if (!isNaN(curr)) {
      return acc + curr;
    }
    return acc;
  }, 0);
};

export const getETA = (e: ProgressEvent<EventTarget>, startTime: number) => {
  const now = new Date().getTime();
  let elapsedtime = now - startTime;
  elapsedtime = elapsedtime / 1000;
  const eta = (e.total / e.loaded) * elapsedtime - elapsedtime;
  return Math.round(eta);
};

export const toPaginationQuery = ({
  page,
  pageSize,
  sort,
  sortDirection,
  ...extras
}: RestQueryExtras): string => {
  return `?page=${page}&size=${pageSize}&sort=${sort},${sortDirection}${Object.entries(
    extras
  ).reduce((acc: string, [k, v]: [string, any]) => `${acc}&${k}=${v}`, '')}`;
};

export const toUTC = (date: Date): number =>
  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

export const toFirstDayMs = (ts: number): number =>
  Date.UTC(new Date(ts).getFullYear(), 0, 1);

export const toDate = (ts: number): Date => new Date(ts);

export const toLocaleDateTimeString = (ts: number | Date ) => {
  var date = new Date(ts);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export const firstUpperCase = (str: string) => {
  return str.substr(0,1).toLocaleUpperCase() + str.substr(1);
}

export const toUTCDateString = (ts: number | null) => {
  if (ts == null) {
    return '';
  }
  const auxDate = toDate(ts);
  return (
    auxDate.getUTCDate().toString().padStart(2, '0') +
    '/' +
    (auxDate.getUTCMonth() + 1).toString().padStart(2, '0') +
    '/' +
    auxDate.getFullYear()
  );
};

export const argsToQuery = <T extends object>(args?: T): string => {
  return args
    ? Object.entries(args).reduce((acc: string, [key, value]): string => {
        return `${acc}&${key}=${value}`;
      }, '')
    : '';
};

const traverseAndFlatten = (
  currentNode: { [K: string]: any },
  target: { [K: string]: any },
  flattenedKey?: string
): void => {
  for (var key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      var newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = flattenedKey + '.' + key;
      }

      var value = currentNode[key];
      if (typeof value === 'object') {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
};

/**
 *
 * @param collection Collection of objects to transform
 * @param valueTransformer Function to transform certain values
 * @param arraySeparator Array character separator. '|' by default
 */
export const collectionToCSV = <T>(
  collection: T[],
  valueTransformer?: (
    key: string,
    value: any,
    el: T,
    flattenedEl: any,
    next: (val: string) => string
  ) => string,
  arraySeparator: string = '|'
): { uri: string; content: string } | undefined => {
  const defaultValueTransformer = (val: any): string => {
    if (Array.isArray(val)) {
      return val.join(arraySeparator);
    } else if (typeof val === 'object') {
      return JSON.stringify(val);
    } else {
      return `"${val?.toString()}"`;
    }
  };

  const firstEl = collection[0];
  if (firstEl) {
    let firstFlattenedUser: any = {};
    traverseAndFlatten(firstEl, firstFlattenedUser);
    const headers: string[] = Object.keys(firstFlattenedUser);
    const csvBody = collection.reduce((acc: string, curr: T) => {
      let flattenedEl: any = {};
      traverseAndFlatten(curr, flattenedEl);
      const headersStr = headers
        .map((K: string) => {
          const val = flattenedEl[K];
          if (!val) {
            return '';
          }
          let parsedVal = '';
          if (valueTransformer) {
            parsedVal = valueTransformer(
              K,
              val,
              curr,
              flattenedEl,
              defaultValueTransformer
            );
          } else {
            parsedVal = defaultValueTransformer(val);
          }
          return parsedVal;
        })
        .join(',');
      return `${acc}\n${headersStr}`;
    }, '');
    const content = `${headers.join(',')}\n${csvBody}`;
    const csv = `data:text/csv;charset=utf-8,${content}`;
    return { uri: encodeURI(csv), content };
  }
  return undefined;
};

export const getCurrentMonthRange = (): [Date, Date] => {
  const today = new Date();
  const currMonth = today.getMonth();
  const currYear = today.getFullYear();
  const auxDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const lastDay = auxDate.getDate();

  const firstDate = new Date(currYear, currMonth, 1);
  const lastDate = new Date(currYear, currMonth, lastDay);

  return [firstDate, lastDate];
};

export const getUserConsume = (
  consume: PUConsumption,
  user: string
): { date: number; pu: number; acc: number }[] => {
  return consume
    .sort((c1, c2) => c1.date - c2.date)
    .reduce(
      (
        acc: { date: number; pu: number; acc: number }[],
        curr: PUConsumptionEntry
      ) => {
        const userEntry = curr.users.find((u) => u.email === user);

        if (userEntry) {
          const userAcc = acc[acc.length - 1];
          if (userAcc) {
            return acc.concat({
              date: curr.date,
              pu: userEntry.pu,
              acc: (userAcc.acc || 0) + userEntry.pu,
            });
          } else {
            return acc.concat({
              date: curr.date,
              pu: userEntry.pu,
              acc: userEntry.pu,
            });
          }
        }

        return acc;
      },
      []
    );
};

export const getTotalConsume = (
  consume: PUConsumption
): { date: number; pu: number; acc: number }[] => {
  let prevAcc = 0;
  return consume
    .sort((c1, c2) => c1.date - c2.date)
    .map((entry) => {
      const usersSum = sum(pluck(entry.users, 'pu'));
      const acc = prevAcc + usersSum;
      const r = {
        date: entry.date,
        pu: usersSum,
        acc,
      };

      prevAcc = acc;
      return r;
    });
};
