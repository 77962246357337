import React from 'react';

import { useTranslation } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Responsive } from './ResponsiveContainer';
import { Row, Column } from './Flex';

import { Alert as IAlert } from 'domain/models';

import './Alert.scss';

export interface AlertProps extends Omit<IAlert, 'component'> {
  dismissMe(innerKey: Symbol): void;
}

export const Alert = React.forwardRef<any, React.PropsWithChildren<AlertProps>>(
  (
    {
      className = '',
      message,
      variant = 'error',
      dismissible = true,
      innerKey,
      timeout = 5000,
      dismissMe,
      children,
    }: React.PropsWithChildren<AlertProps>,
    ref: any
  ) => {
    const { t } = useTranslation('common');

    React.useEffect(() => {
      let timeoutId: any,
        mounted = true;
      timeoutId = setTimeout(() => {
        if (mounted) {
          dismissMe(innerKey);
        }
      }, timeout);

      return () => {
        mounted = false;
        if (timeoutId) {
          clearTimeout(timeout);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const DismissButton = () => (
      <span className='dismiss-button' onClick={() => dismissMe(innerKey)}>
        {t('common:components.Alert.dismiss')}
      </span>
    );

    const Content = (): any =>
      message ? <span className='alert-content'>{message}</span> : children;

    return (
      <Responsive>
        <Row className={`alert ${className} ${variant}`} ref={ref}>
          <Content />
          {dismissible && <DismissButton />}
        </Row>
      </Responsive>
    );
  }
);

export interface AlertContainerProps {
  alerts: IAlert[];
  dismissAlert(innerKey: Symbol): void;
}

export const AlertContainer = ({
  alerts,
  dismissAlert,
}: AlertContainerProps) => {
  return (
    <Responsive>
      <Column className='alert-container'>
        <TransitionGroup className='alerts'>
          {alerts.map((alert, index) => {
            return (
              <CSSTransition
                timeout={200}
                classNames='alert-transition'
                key={index}
              >
                <Alert
                  innerKey={alert.innerKey}
                  dismissMe={(innerKey: Symbol) => dismissAlert(innerKey)}
                  message={alert.message}
                  variant={alert.variant}
                  timeout={alert.timeout}
                ></Alert>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </Column>
    </Responsive>
  );
};
