import React from 'react';

import './Progress.scss';

type ProgressProps = React.DetailedHTMLProps<
React.ProgressHTMLAttributes<HTMLProgressElement>,
HTMLProgressElement
>;
export const Progress = ({className, ...props}: ProgressProps) => {
  return <progress className={`progress ${className}`} {...props} />
}