import React from 'react';

import './Paragraph.scss';

type PProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

export const Paragraph = React.forwardRef((props: PProps, ref: any) => {
  const { className, children, ...otherProps } = props;

  return (
    <p className={`paragraph ${className || ''}`} {...otherProps}>
      {children}
    </p>
  );
});
