import React from 'react';

import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { Row, Column } from './Flex';
import { Button } from './Button';
import { H } from './Heading';
import { UI } from './Icons';
import { Responsive } from './ResponsiveContainer';

import './Modal.scss';

export interface ModalProps {
  show: boolean;
  title: string;
  dismissable?: boolean;
  className?: string;
  onClose: VoidFunction;
}
export const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const { t } = useTranslation('common');

  const backdrop = React.useRef<HTMLDivElement>();

  const dismissOnClick = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (evt.target === backdrop?.current) {
      if (props.dismissable) {
        props.onClose && props.onClose();
      }
    }
  };

  return (
    <CSSTransition
      classNames='modal-transition'
      timeout={200}
      unmountOnExit
      in={props.show}
    >
      <Responsive>
        <Row
          className={`modal-backdrop ${props.className || ''}`}
          ref={backdrop}
          onClick={dismissOnClick}
        >
          <Column className='modal'>
            <UI.CloseBigIcon
              onClick={props.onClose}
              title={t('common:components.Modal.close')}
              className='modal-close-icon'
            />

            <Column className='modal-body'>
              <H.H1 className='modal-title'>{props.title}</H.H1>
              <Row className='modal-message'>{props.children}</Row>
            </Column>
          </Column>
        </Row>
      </Responsive>
    </CSSTransition>
  );
};

export interface ConfirmationModalProps {
  title: string;
  message: string | React.Component;
  show: boolean;
  className?: string;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}
export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation('common');

  return (
    <Modal
      className={`confirmation-modal ${props.className || ''}`}
      title={props.title}
      dismissable={false}
      show={props.show}
      onClose={props.onCancel}
    >
      <Row className='confirmation-modal-content'>{props.message}</Row>
      <Row className='confirmation-modal-actions'>
        <Button secondary onClick={props.onConfirm}>
          {t('common:components.Modal.confirm')}
        </Button>
        <Button onClick={props.onCancel}>
          {t('common:components.Modal.cancel')}
        </Button>
      </Row>
    </Modal>
  );
};
