import React from 'react';

import { useIsMobile } from 'domain/hooks';

export const Responsive = ({ children }: React.PropsWithChildren<{}>) => {
  const isMobile = useIsMobile();

  return React.Children.map(children, (child: any) =>
    React.cloneElement(child, {
      ...child.props,
      className: `${child.props.className || ''} ${isMobile ? 'mobile' : ''}`
    })
  ) as any;
};
