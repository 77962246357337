import { Api } from 'domain/core';

export class SubscriptionService {
    subscribe(email: string) : Promise<boolean> {
        return fetch(`${Api.baseUrl}${Api.subscription.subscribe}?email=${email}`, {
            method: 'post',
          }).then((res: Response) => {
            return res.status === 204;
        }).catch((err) => {
            return false;
        });
    }

    verify(email: string, key: string) : Promise<boolean> {
        return fetch(`${Api.baseUrl}${Api.subscription.verification}?email=${email}&key=${key}`, {
            method: 'post',
          }).then((res: Response) => {
            return res.status === 204;
        }).catch((err) => {
            return false;
        });
    }

    unsubscribe(email: string, key: string) : Promise<boolean> {
        return fetch(`${Api.baseUrl}${Api.subscription.unsubscribe}?email=${email}&key=${key}`, {
            method: 'post',
          }).then((res: Response) => {
            return res.status === 204;
        }).catch((err) => {
            return false;
        });
    }
}
