// Landing icons
import { ReactComponent as CopernicusIcon } from 'assets/icons/copernicus_logo.svg';
import { ReactComponent as CSICIcon } from 'assets/icons/csic.svg';
import { ReactComponent as CustomIcon } from 'assets/icons/custom.svg';
import { ReactComponent as EasyFastIcon } from 'assets/icons/easyfast.svg';
import { ReactComponent as HeroIcon } from 'assets/icons/hero.svg';
import { ReactComponent as LinesIcons } from 'assets/icons/lines.svg';
import { ReactComponent as PredictiaIcon } from 'assets/icons/predictia_logo.svg';
import { ReactComponent as ReadyToUseIcon } from 'assets/icons/readytouse.svg';
import { ReactComponent as ReliableIcon } from 'assets/icons/reliable.svg';

// UI Icons
import { ReactComponent as PayPerUsePlan } from 'assets/icons/ui/advanced_plan.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/ui/arrow.svg';
import { ReactComponent as CalendarDayIcon } from 'assets/icons/ui/calendar_day.svg';
import { ReactComponent as CalendarMonthIcon } from 'assets/icons/ui/calendar_month.svg';
import { ReactComponent as CalendarYearIcon } from 'assets/icons/ui/calendar_year.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/ui/calendar.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/ui/cancel.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/ui/check.svg';
import { ReactComponent as CloseBigIcon } from 'assets/icons/ui/close_big.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/ui/credit_card.svg';
import { ReactComponent as DatasetIcon } from 'assets/icons/ui/dataset.svg';
import { ReactComponent as DatasetProjIcon } from 'assets/icons/ui/dataset_proj.svg';
import { ReactComponent as DatasetRefIcon } from 'assets/icons/ui/dataset_ref.svg';
import { ReactComponent as DocumentationIcon } from 'assets/icons/ui/documentation_standardplan.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/ui/download.svg';
import { ReactComponent as ExperimentsIcon } from 'assets/icons/ui/experiment.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/ui/eye.svg';
import { ReactComponent as FAQSIcon } from 'assets/icons/ui/faqs.svg';
import { ReactComponent as UpgradePlan } from 'assets/icons/ui/upgrade-plan.svg';
import { ReactComponent as FreePlanIcon } from 'assets/icons/ui/free_plan.svg';
import { ReactComponent as GridIcon } from 'assets/icons/ui/grid.svg';
import { ReactComponent as IconsMenuIcon } from 'assets/icons/ui/icons_menu.svg';
import { ReactComponent as IconsIcon } from 'assets/icons/ui/icons.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ui/info.svg';
import { ReactComponent as ListIcon } from 'assets/icons/ui/list.svg';
import { ReactComponent as LockCloseIcon } from 'assets/icons/ui/lock_close.svg';
import { ReactComponent as LockOpenIcon } from 'assets/icons/ui/lock_open.svg';
import { ReactComponent as NotificationEmptyIcon } from 'assets/icons/ui/notification_1.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/ui/notification_4.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/ui/play.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/ui/plus_add.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as StopIcon } from 'assets/icons/ui/stop.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/ui/support.svg';
import { ReactComponent as UserIcon } from 'assets/icons/ui/user.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/ui/warning.svg';

// Brand icons
import { ReactComponent as InlineBlack } from 'assets/icons/brand/inline/Climadjust_LI-BLACK.svg';
import { ReactComponent as InlinePositive } from 'assets/icons/brand/inline/Climadjust_LI-POS.svg';
import { ReactComponent as InlineWhite } from 'assets/icons/brand/inline/Climadjust_LI-WHITE.svg';

import { ReactComponent as StackedBlack } from 'assets/icons/brand/stacked/Climadjust_LS-BLACK.svg';
import { ReactComponent as StackedPositive } from 'assets/icons/brand/stacked/Climadjust_LS-POS.svg';
import { ReactComponent as StackedWhite } from 'assets/icons/brand/stacked/Climadjust_LS-WHITE.svg';

import { ReactComponent as IsotypeBlack } from 'assets/icons/brand/isotype/Climadjust_IS-BLACK.svg';
import { ReactComponent as IsotypePositive } from 'assets/icons/brand/isotype/Climadjust_IS-POS.svg';
import { ReactComponent as IsotypeWhite } from 'assets/icons/brand/isotype/Climadjust_IS-WHITE.svg';

export const LandingIcons = {
  CopernicusIcon,
  CSICIcon,
  CustomIcon,
  EasyFastIcon,
  HeroIcon,
  LinesIcons,
  PredictiaIcon,
  ReadyToUseIcon,
  ReliableIcon,
};

export const BrandIcons = {
  Inline: {
    Black: InlineBlack,
    Positive: InlinePositive,
    White: InlineWhite,
  },
  Stacked: {
    Black: StackedBlack,
    Positive: StackedPositive,
    White: StackedWhite,
  },
  Isotype: {
    Black: IsotypeBlack,
    Positive: IsotypePositive,
    White: IsotypeWhite,
  },
};

export const UI = {
  PayPerUsePlan,
  ArrowIcon,
  CalendarDayIcon,
  CalendarMonthIcon,
  CalendarYearIcon,
  CalendarIcon,
  CancelIcon,
  CheckIcon,
  CloseBigIcon,
  CreditCardIcon,
  DatasetIcon,
  DatasetProjIcon,
  DatasetRefIcon,
  DocumentationIcon,
  DownloadIcon,
  ExperimentsIcon,
  EyeIcon,
  FAQSIcon,
  UpgradePlan,
  FreePlanIcon,
  GridIcon,
  IconsMenuIcon,
  IconsIcon,
  InfoIcon,
  ListIcon,
  LockCloseIcon,
  LockOpenIcon,
  NotificationEmptyIcon,
  NotificationIcon,
  PlayIcon,
  PlusIcon,
  StarIcon,
  StopIcon,
  SupportIcon,
  UserIcon,
  WarningIcon,
};
