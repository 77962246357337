import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import './Button.scss';
import { UI } from './Icons';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button = React.forwardRef(
  (
    props: ButtonProps & {
      secondary?: boolean;
      borderless?: boolean;
      small?: boolean;
      danger?: boolean;
    },
    ref: any
  ) => {
    const {
      className,
      secondary = false,
      borderless = false,
      small = false,
      danger = false,
      ...otherProps
    } = props;

    return (
      <button
        {...otherProps}
        ref={ref}
        className={`button ${secondary ? 'secondary' : ''} ${
          borderless ? 'borderless' : ''
        } ${small ? 'small' : ''} ${danger ? 'danger' : ''} ${className || ''}`}
      >
        {props.children}
      </button>
    );
  }
);

export const LoadingButton = React.forwardRef(
  (
    props: ButtonProps & {
      loading?: boolean;
      secondary?: boolean;
      borderless?: boolean;
      small?: boolean;
    },
    ref: any
  ) => {
    const { className, loading, disabled = false, ...otherProps } = props;

    return (
      <Button
        disabled={disabled || loading}
        ref={ref}
        className={`loading-button ${className || ''}`}
        {...otherProps}
      >
        {props.children}
        {props.loading && (
          <FontAwesomeIcon
            className='button-loading-indicator'
            icon={faCircleNotch}
          />
        )}
      </Button>
    );
  }
);

export const ButtonWithIcon = React.forwardRef(
  (
    props: ButtonProps & {
      icon: IconDefinition;
      iconClassName?: string;
      secondary?: boolean;
      borderless?: boolean;
      small?: boolean;
    },
    ref: any
  ) => {
    const { className, iconClassName = '', icon, ...otherProps } = props;

    return (
      <Button
        ref={ref}
        className={`button-w-icon ${className || ''}`}
        {...otherProps}
      >
        {props.children}
        <FontAwesomeIcon
          icon={icon}
          className={`button-w-icon__icon ${iconClassName}`}
        />
      </Button>
    );
  }
);

export const BackButton = React.forwardRef(
  (
    props: ButtonProps & {
      iconClassName?: string;
      secondary?: boolean;
    },
    ref: any
  ) => {
    const { className, iconClassName = '', ...otherProps } = props;

    return (
      <Button
        ref={ref}
        small
        borderless
        secondary
        className={`back-button ${className || ''}`}
        {...otherProps}
      >
        <UI.ArrowIcon className={`back-button-icon ${iconClassName}`} />
        {props.children}
      </Button>
    );
  }
);
