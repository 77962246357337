import { AuthorizedService } from './authorized-service.base';
import { Method, MethodParameter } from 'domain/models';
import { Api } from 'domain/core';

export class MethodService extends AuthorizedService {
  public getMethods(): Promise<Method[]> {
    // return this.fetch(Api.baseUrl + Api.methods.base).then((r) => r.json());
    return fetch('/__mock__/bias-methods.json').then((r) => r.json());
  }

  public getMethod(methodId: number): Promise<Method> {
    return this.fetch(
      `${Api.baseUrl}${Api.methods.base}/${methodId}`
    ).then((r) => r.json());
  }

  public getAllParameters(): Promise<MethodParameter[]> {
    return this.fetch(
      Api.baseUrl + Api.methods.base + Api.methods.parameters
    ).then((r) => r.json());
  }

  public getParameter(parameterId: number): Promise<MethodParameter> {
    return this.fetch(
      `${Api.baseUrl}${Api.methods.base}${Api.methods.parameters}/${parameterId}`
    ).then((r) => r.json());
  }
}
