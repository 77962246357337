import { createActionCreator } from 'deox';

import { Alert, FeedbackAction } from 'domain/models';

const PUSH_ALERT = '[Root] :: Add alert';
const REMOVE_ALERT = '[Root] :: Remove alert';

const TRIGGER_ACTION = '[Root] :: Trigger action';

export const pushAlert = createActionCreator(
  PUSH_ALERT,
  (resolve) => (alert: Omit<Alert, 'innerKey'>) => resolve(alert)
);

export const removeAlert = createActionCreator(
  REMOVE_ALERT,
  (resolve) => (key: Symbol) => resolve(key)
);

export const triggerAction = createActionCreator(
  TRIGGER_ACTION,
  (resolve) => (
    action: FeedbackAction | null
  ) => resolve(action)
);
