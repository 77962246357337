import React, { CSSProperties } from 'react';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

import { Row } from 'components';

import './ListHeader.scss';
import { UI } from './Icons';

export type ListKey = { name: string; code: string; sortable?: boolean, style?: CSSProperties; };
export interface ListHeaderProps {
  keys: ListKey[];
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
  displayMode: 'grid' | 'list';
  onSortChange(sort: string, direction: 'ASC' | 'DESC'): void;
  onDisplayModeChange(mode: 'grid' | 'list'): void;
}
export const ListHeader = (props: ListHeaderProps) => {
  const { t } = useTranslation('common');

  const SortIcon = ({ propName }: { propName: string }) => {
    if (props.sortBy === propName) {
      return props.sortDirection === 'ASC' ? (
        <FontAwesomeIcon icon={faChevronDown} />
      ) : (
        <FontAwesomeIcon icon={faChevronUp} />
      );
    } else {
      return <FontAwesomeIcon icon={faMinus} />;
    }
  };

  const onSortClick = (propName: string) => {
    if (props.sortBy === propName) {
      if (props.sortDirection === 'ASC') {
        props.onSortChange(propName, 'DESC');
      } else {
        props.onSortChange(propName, 'ASC');
      }
    } else {
      props.onSortChange(propName, 'ASC');
    }
  };

  const onDisplayClick = (mode: 'grid' | 'list') => {
    props.onDisplayModeChange(mode);
  };

  const style = { flexBasis: `${90 / props.keys.length}%` };
  
  return (
    <Row className='list-header'>
      {props.keys.map((k) => (
        <Row
          key={k.code}
          className={`list-header-item ${k.code}`}
          style={ { ...style, ...k.style }}
          onClick={() => {
            k.sortable !== false && onSortClick(k.code);
          }}
        >
          {k.name} {k.sortable !== false && <SortIcon propName={k.code} />}
        </Row>
      ))}
      <Row className='list-header-icon-container'>
        <UI.IconsIcon
          title={t('common:components.ListHeader.gridView')}
          onClick={() => onDisplayClick('grid')}
          className={`display-mode-icon grid-icon ${
            props.displayMode === 'grid' ? 'active' : ''
          }`}
        />
        <UI.ListIcon
          title={t('common:components.ListHeader.listView')}
          onClick={() => onDisplayClick('list')}
          className={`display-mode-icon list-icon ${
            props.displayMode === 'list' ? 'active' : ''
          }`}
        />
      </Row>
    </Row>
  );
};
