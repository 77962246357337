import { Ketting } from 'ketting'; // Ketting es 'cadena' en Neerlandés. Nada que ver con gatitos al parecer...

import { isValid } from 'domain/utils';
import { AuthorizationService } from 'domain/services';

import { Token, PageInfo } from 'domain/models';
import { Api } from 'domain/core';

const defaultKetting = (token: Token) =>
  new Ketting(Api.baseUrl + '/rest', {
    auth: {
      type: 'bearer',
      token: token.token
    }
  });

const initialKetting = new Ketting(Api.baseUrl + '/rest', {
  auth: {
    type: 'bearer',
    token: ''
  }
});

export class AuthKetting {
  private ketting: Ketting = initialKetting;

  static renewSubscribers: Function[] = [];

  public defaultPage: PageInfo = {
    number: 0,
    size: 10,
    totalElements: 0,
    totalPages: 0
  };

  constructor() {
    const authorization = this.getAuthorization();
    if (isValid(authorization)[0]) {
      this.ketting.getOptions().then(kettingOptions => {
        if (kettingOptions.auth?.type === 'bearer') {
          kettingOptions.auth.token = authorization.token;
        }
      });
    }
  }

  protected async getKetting(): Promise<Ketting> {
    const authorization = this.getAuthorization();
    const [valid, lifetime] = isValid(authorization);
    if (valid && lifetime > 1000 * 300) {
      return new Promise(res => {
        if (this.ketting) {
          res(this.ketting);
        } else {
          res(defaultKetting(authorization));
        }
      });
    } else {
      const token = await new AuthorizationService().renew();
      this.notify(token);
      if (this.ketting) {
        const kettingOptions = await this.ketting.getOptions();
        if (kettingOptions.auth?.type === 'bearer') {
          kettingOptions.auth.token = token.token;
        }
        return this.ketting;
      } else {
        return defaultKetting(token);
      }
    }
  }

  public templatedPage(page: PageInfo): { page: string; size: string, sort: string } {
    return {
      page: page.number.toString(),
      size: page.size.toString(),
      sort: page.sort || ''
    };
  }

  private getAuthorization(): Token {
    try {
      const auth = localStorage.getItem('auth:token');
      return JSON.parse(auth || '') as Token;
    } catch (err) {
      throw new Error('AuthKetting :: Unable to retrieve authorization!');
    }
  }

  private notify(token: Token) {
    AuthKetting.renewSubscribers.forEach(callback => {
      callback && callback(token);
    });
  }

  static subscribeToRenew(subscribeCB: (token: Token) => void): void {
    AuthKetting.renewSubscribers.push(subscribeCB);
  }

  static removeRenewSubscription(subscribeCB: (token: Token) => void): void {
    AuthKetting.renewSubscribers = AuthKetting.renewSubscribers.filter(
      f => f !== subscribeCB
    );
  }
}
