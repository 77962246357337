import { AuthorizedService } from './authorized-service.base';
import { RestQueryExtras, Notification } from 'domain/models';
import { Defaults, Api } from 'domain/core';
import { toPaginationQuery } from 'domain/utils';

export class NotificationService extends AuthorizedService {
  public getUnreadNotifications(
    extras: RestQueryExtras = Defaults.defaultRestExtras
  ): Promise<Notification[]> {
    return this.fetch(
      `${Api.baseUrl}${Api.notifications.unread}${toPaginationQuery(extras)}`
    ).then((r) => r.json());
  }

  public getAllNotifications(
    extras: RestQueryExtras = Defaults.defaultRestExtras
  ): Promise<Notification[]> {
    return this.fetch(
      `${Api.baseUrl}${Api.notifications.all}${toPaginationQuery(extras)}`
    ).then((r) => r.json());
  }
}
