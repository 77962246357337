import React from 'react';

import { Row, Column } from './Flex';

import './Loading.scss';

export interface LoadingProps {
  indeterminate?: boolean;
  progress?: number;
  className?: string;
}
export const Loading = ({
  indeterminate,
  progress,
  className,
  children,
}: React.PropsWithChildren<LoadingProps>) => {

  const circle = React.useRef<SVGCircleElement | null>(null);

  const [circumference, setCircumference] = React.useState(0);

  React.useEffect(() => {
    if (circle.current) {
      const radius = circle.current.r.baseVal.value;
      const _circumference = radius * 2 * Math.PI;

      setCircumference(_circumference);
    }
  }, []);

  return (
    <div
      className={`loading-spinner ${
        indeterminate ? 'indeterminate' : 'controlled'
      } ${className || ''}`}
    >
      <Row className='text-content'>
        {children ? (
          children
        ) : indeterminate ? (
          <Row className='indeterminate-text'>
            Loading
            <span className='bounce-ball' />
            <span className='bounce-ball' />
            <span className='bounce-ball' />
          </Row>
        ) : (
          <Column className='value-text'>
            <span>{progress}%</span>
          </Column>
        )}
      </Row>
      <svg className='circular' viewBox='25 25 50 50'>
        <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='3%' stopColor='#ef7a04' />
          <stop offset='23%' stopColor='#ffd86f' />
          <stop offset='42%' stopColor='#ffd86f' />
          <stop offset='64%' stopColor='#68c3cd' />
          <stop offset='86%' stopColor='#68c3cd' />
          <stop offset='103%' stopColor='#005a66' />
        </linearGradient>
        <circle
          ref={circle}
          className='path'
          cx='50'
          cy='50'
          r='20'
          fill='none'
          stroke='url(#gradient)'
          strokeWidth='2'
          strokeMiterlimit='10'
          strokeDasharray={`${circumference}`}
          strokeDashoffset={progress && `${( (100 - progress) / 100) * circumference}`}
        />
      </svg>
    </div>
  );
};

export const FixedLoading = ({ immediate }: { immediate?: boolean }) => {
  const [show, setShow] = React.useState(immediate);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 300);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  return (
    <Row className='fixed-loading-container'>
      {show && <Loading indeterminate className='fixed-loading-spinner' />}
    </Row>
  );
};
