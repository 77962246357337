import { AuthorizedService } from './authorized-service.base';
import {
  SupportTicket,
  NewSupportTicketFormData,
  RestQueryExtras,
  Pageable,
  User,
} from 'domain/models';
import { Api, Defaults } from 'domain/core';
import { toPaginationQuery, isEqualOrHigherTierByKey } from 'domain/utils';

export class SupportService extends AuthorizedService {
  private url: string;

  constructor(user?: User) {
    super();
    if (isEqualOrHigherTierByKey(user?.roles || 'ROLE_USER', 'ROLE_TUTOR', true)) {
      this.url = Api.support.tutor;
    } else {
      this.url = Api.support.base;
    }
  }

  public getTickets(
    status: SupportTicket['state'],
    pageInfo: RestQueryExtras = Defaults.defaultRestExtras
  ): Promise<Pageable<SupportTicket[]>> {
    return this.fetch(
      `${Api.baseUrl}${this.url}${toPaginationQuery(pageInfo)}&status=${status}`
    ).then((r) => r.json());
  }

  public getTicket(id: number): Promise<SupportTicket> {
    return this.fetch(`${Api.baseUrl}${this.url}/${id}`).then((r) => r.json());
  }

  public createTicket(ticket: NewSupportTicketFormData): Promise<any> {
    return this.fetch(`${Api.baseUrl}${this.url}`, {
      body: JSON.stringify(ticket),
      method: 'POST',
    }).then(async r => {
      if (r.status === 201) {
        return r.json();
      } else {
        const payload = await r.json();
        throw payload;
      }
    });
  }

  public closeTicket(ticketId: number): Promise<boolean> {
    return this.fetch(`${Api.baseUrl}${this.url}/${ticketId}`, {
      method: 'PATCH',
      body: JSON.stringify({ state: 'CLOSED' }),
    }).then((r) => {
      if (r.status === 204) {
        return r.ok;
      } else {
        return r.json();
      }
    });
  }

  public pushComment(ticketId: number, comment: string): Promise<any> {
    return this.fetch(
      `${Api.baseUrl}${this.url}/${ticketId}${Api.support.messages}`,
      {
        method: 'POST',
        body: JSON.stringify({ textContent: comment }),
      }
    ).then((r) => {
      if (r.status === 201) {
        return r.json();
      } else {
        return r.json();
      }
    });
  }

  public uploadAttachments(ticketId: number, formData: FormData): Promise<boolean> {
    return this.getValidToken().then(token => {
      return fetch( `${Api.baseUrl}${this.url}/${ticketId}${Api.support.attachments}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
        body: formData
      }).then(r => r.status === 201);
    })
  }

  public uploadCommentAttachment(ticketId: number, commentId: number, formData: FormData): Promise<boolean> {
    return this.getValidToken().then(token => {
      return fetch( `${Api.baseUrl}${this.url}/${ticketId}/${commentId}${Api.support.attachments}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
        body: formData
      }).then(r => r.status === 201);
    })
  }

  public getAttachments(ticketId: number): Promise<any> {
      return this.fetch( `${Api.baseUrl}${this.url}/${ticketId}${Api.support.attachments}`)
        .then(r => r.json());
  }

  public downloadAttachment(ticketId: string, attachmentId: number): Promise<Blob | null> {
    return this.fetch( `${Api.baseUrl}${this.url}/${ticketId}${Api.support.attachments}/${attachmentId}`)
      .then(r => { 
        if (r.status === 200) {
          return r.blob()
        } else {
          return null
        } 
      });
  }
}
