import { isEqualOrHigherTierByKey } from 'domain/utils';

import { UserRole, AppStore } from 'domain/models';
import { useSelector } from 'react-redux';

type WithRoleRT<AllowedFN extends Function, NotAllowedFN extends Function> =
  | [AllowedFN, true]
  | [NotAllowedFN, false];

const noop = (...args: any) => {
  console.warn('Action not allowed!');
};

export const useWithRole = <
  AllowedFN extends Function,
  NotAllowedFN extends Function
>(
  allowedFn: AllowedFN,
  targetTier: UserRole['role'],
  notAllowedFn?: NotAllowedFN,
  exact: boolean = false
): WithRoleRT<AllowedFN, NotAllowedFN> => {
  const userRoles = useSelector((store: AppStore) => store.auth.user?.roles);

  if (isEqualOrHigherTierByKey(userRoles || 'ROLE_USER', targetTier, exact)) {
    return [allowedFn, true];
  } else {
    return [notAllowedFn || (noop as any), false];
  }
};
