import React from 'react';
import { unstable_batchedUpdates } from 'react-dom';

type Unpromisify<T> = T extends Promise<infer R> ? R : T;

type PromiseLikeResolvedReturnType<
  FunctionType extends (...args: any) => Promise<any>
> = Unpromisify<ReturnType<FunctionType>>;

export type ApiHookResponse<FT extends (...args: any[]) => any> = [
  PromiseLikeResolvedReturnType<FT> | null,
  boolean,
  Error | null,
  (
    context?: ThisParameterType<FT>
  ) => (...params: Parameters<FT>) => Promise<PromiseLikeResolvedReturnType<FT>>
];

export const useApi = <FT extends (...args: any[]) => Promise<any>>(
  request: FT
): ApiHookResponse<FT> => {
  const [data, setData] = React.useState<PromiseLikeResolvedReturnType<
    FT
  > | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);

  const makeApiCall = React.useCallback(
    async (
        context: ThisParameterType<FT> | null = null,
      ...params: Parameters<FT>
    ): Promise<PromiseLikeResolvedReturnType<FT>> => {
      unstable_batchedUpdates(() => {
        setLoading(true);
        setError(null);
      });

      try {
        const data = await request.call(context, ...params);
        unstable_batchedUpdates(() => {
          setData(data);
          setLoading(false);
        });
        return data;
      } catch (err) {
        unstable_batchedUpdates(() => {
          setError(err);
          setLoading(false);
        });
        throw new Error(err);
      }
    },
    [request]
  );

  const initializer = React.useCallback(
    (context?: ThisParameterType<FT>) => async (
      ...params: Parameters<FT>
    ): Promise<PromiseLikeResolvedReturnType<FT>> => {
      const p = [context || null, ...params]
      return await (makeApiCall as any).call(null, ...p);
    },
    [makeApiCall]
  );

  return [data, loading, error, initializer];
};
