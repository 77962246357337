import React from 'react';

import './Form.scss';

export type FormProps = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

export const Form = React.forwardRef((props: FormProps, ref: any) => {
  const { className, ...otherProps } = props;

  return (
    <form className={`form ${className || ''}`} ref={ref} {...otherProps}>
      {otherProps.children}
    </form>
  );
});
