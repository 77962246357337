import React from 'react';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Horizontal = React.forwardRef(
  (props: DivProps & { height: string; wTitle?: boolean }, ref: any) => {
    const { height, style, wTitle, className = '', ...otherProps } = props;
    return (
      <div
        className={`divider ${className} ${wTitle ? 'w-title' : ''}`}
        style={{ ...style, height }}
        {...otherProps}
        ref={ref}
      ></div>
    );
  }
);

const Vertical = React.forwardRef(
  (props: DivProps & { width: string; wTitle?: boolean }, ref: any) => {
    const { width, style, wTitle, className = '', ...otherProps } = props;
    return (
      <div
        className={`divider ${className} ${wTitle ? 'w-title' : ''}`}
        style={{ ...style, width }}
        {...otherProps}
        ref={ref}
      ></div>
    );
  }
);

export const Divider = {
  Horizontal,
  Vertical,
};
