import React from 'react';

import { useDispatch } from 'react-redux';
import ReactGa from 'react-ga4';

import { Router } from 'pages/Router';

import { GaMeasurementID } from 'domain/core/constants';
import { useLocalStorage } from 'domain/hooks';
import { AnalyticsService, AuthorizationService } from 'domain/services';
import { isValid } from 'domain/utils';
import { loginSuccess } from 'domain/store/auth';

import './App.scss';

const App = () => {
  const dispatch = useDispatch();

  const [allowCookies] = useLocalStorage('cookies-allow', true);
  ReactGa.initialize(GaMeasurementID, {
    gaOptions: {
      cookieFlags: 'SameSite=None; Secure',
    },
  });

  React.useEffect(() => {
    try {
      const token = AuthorizationService.getAuthorization();
      const [valid, remaining] = isValid(token);
      if (valid && remaining > 300 * 1000) {
        dispatch(loginSuccess(token));
      }
    } catch (err) {
      // IGNORE
    }
  }, []);

  React.useEffect(() => {
    if (allowCookies) {
      AnalyticsService.enable();
    } else {
      AnalyticsService.disable();
    }
  }, [allowCookies]);

  return <Router />;
};

export default App;
