import { PageInfo, User } from 'domain/models';
import { AuthKetting } from '../ketting.auth';
import { Api } from 'domain/core';
import { assert } from 'domain/utils';

export class UsersService extends AuthKetting {
  public async getUsers(
    pageInfo: PageInfo = this.defaultPage
  ): Promise<User[]> {
    const ketting = await this.getKetting();
    const users = await ketting
      .follow('users', this.templatedPage(pageInfo))
      .followAll('users');
    return Promise.all(users.map(u => u.get()));
  }

  public async getUserByEmail(userEmail: string): Promise<any> {
    const ketting = await this.getKetting();
    return await ketting
      .follow('users', {})
      .follow('search')
      .follow('findByEmail', {
        email: userEmail
      });
  }

  public async patchUser(
    userId: string, user: Partial<User>): Promise<any> {
    // return (await ketting.follow('users', {email: userId})).patch(user);
    const ketting = await this.getKetting();
    return ketting
      .fetch(Api.baseUrl + "/rest/users/" + userId, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
      .then(r => {
        assert(r.status === 200);
      });
  }
}