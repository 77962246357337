import React from 'react';

import { Row } from './Flex';
import { UI } from './Icons';

import { useSelector } from 'react-redux'

import { mergeRefs } from 'domain/utils';

import { AppStore } from 'domain/models'

import './AvatarImage.scss';

export type ImageType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;
export const AvatarImage = React.forwardRef(
  (props: ImageType & { username?: string }, ref: any) => {
    const { className = '', username, ...nativeProps } = props;

    const imgRef = React.useRef<HTMLImageElement>(null);

    const [showAvatar, setShowAvatar] = React.useState(false);
    
    const avatarSrc = useSelector((root: AppStore) => root.auth.avatarSrc);

    React.useEffect(() => {
      let mounted = true;
      const cb = () => {
        if (mounted) {
          setShowAvatar(false);
        }
      };
      const checkAuth = async () => {
        try {

          const img = imgRef.current;

          setShowAvatar(true);

          if (img) {
            img.addEventListener('error', cb);
          }

          return () => {
            img?.removeEventListener('error', cb);
            mounted = false;
          };
        } catch (err) {
          setShowAvatar(false);
        }
      };

      checkAuth().then();
    }, []);

    return (
      <Row className={`avatar-image-wrapper ${className}`}>
        {showAvatar && username && avatarSrc ? (
          <img
            {...nativeProps}
            className='avatar-image'
            src={avatarSrc}
            ref={mergeRefs(imgRef, ref)}
            alt='User avatar'
          />
        ) : (
          <UI.UserIcon className='avatar-icon' />
        )}
      </Row>
    );
  }
);
