import { User, UserPlanDto, UserSubscriptionDto, UserSummary } from 'domain/models';
import { Api } from 'domain/core';
import { assert } from 'domain/utils';
import { AuthorizedService } from './authorized-service.base';
import { useEffect, useState } from 'react';

export class AccountService extends AuthorizedService {
  public async getCurrent(): Promise<User> {
    return this.fetch(Api.baseUrl + Api.auth.account).then((r) => r.json());
  }

  public async getSummary(): Promise<UserSummary> {
    return this
      .fetch(`${Api.baseUrl}${Api.auth.account}${Api.auth.summary}`)
      .then((r) => r.json());
  }

  public async getCapabilities(): Promise<UserPlanDto> {
    return this
      .fetch(`${Api.baseUrl}${Api.auth.capabilities}`)
      .then((r) => r.json());
  }

  public canSubscribe() {
    return this.fetch(`${Api.baseUrl}${Api.auth.account}/subscriptions/can-subscribe`)
      .then((r) => r.json())
      .then(r => r.canSubscribe);
  }

  public getSubscription(): Promise<UserSubscriptionDto> {
    return this.fetch(`${Api.baseUrl}${Api.auth.account}/subscriptions/current`)
      .then((r) => r.json());
  }
  
  upgrade(planName: string) {   
    return this
      .fetch(`${Api.baseUrl}${Api.auth.account}/subscriptions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ planName }),
      })
      .then((r) => {
        assert(r.status === 204);
      });
  }
  
  downgrade() {   
    return this
      .fetch(`${Api.baseUrl}${Api.auth.account}/subscriptions/current`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((r) => {
        assert(r.status === 204);
      });
  }

  public async updateAccount(account: Partial<User>): Promise<void> {

    return this
      .fetch(Api.baseUrl + Api.auth.account, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(account),
      })
      .then((r) => {
        assert(r.status === 204);
      });
  }
}

export const useCanSubscribe = () => {
  const [canSubscribe, setCanSubscribe] = useState(false);

  useEffect(() => {
    const accountService = new AccountService();
    accountService.canSubscribe().then(canSubscribe => setCanSubscribe(canSubscribe));
  }, []);

  return canSubscribe;
};
