import React from 'react';

import './Link.scss';

export const Link = React.forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    ref: any
  ) => {
    const { className, ...otherProps } = props;

    return (
      <a className={`link ${className || ''}`} ref={ref} {...otherProps} rel='noopener noreferrer'>
        {props.children}
      </a>
    );
  }
);
